import { Menu } from "primereact/menu";
import { MenuItem, MenuItemOptions } from "primereact/menuitem";
import { classNames } from "primereact/utils";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "../TranslationProvider";
import getMenuItems from "./MainMenuItems";

export default function AppMenu() {
  const location = useLocation()
  const t = useTranslation()

  const comparePath = (target:string) => location.pathname === target ? 'active' : ''

  const template = (item:MenuItem, options:MenuItemOptions) => {
    return (<Link to={item.url || '#'} className={options.className} target={item.target} onClick={(e) => {
        options.onClick(e)
      }}>
      <span className={classNames(options.iconClassName)}></span>
      <span className={options.labelClassName}>{item.label}</span>
    </Link>)
  }

  const menuModel = getMenuItems(comparePath, template).map(item => ({ ...item, label: t[item.tKey] }))

  return (<Menu model={menuModel} className="main-menu" />)
}