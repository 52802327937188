import React from 'react'
import { useBreakpoints } from 'react-match-breakpoints'
import MainDrawer from '../components/MainDrawer'
import MainDrawerFixed from '../components/MainDrawerFixed'
import useLayoutStore from '../stores/LayoutStore'
import './BlankLayout.scss'

type ContentLayoutProps = {
  className?: string
}

const BlankLayout = React.memo((props: React.PropsWithChildren<object> & ContentLayoutProps) => {
  const { showMenuDrawer } = useLayoutStore()
  const { currentTheme } = useLayoutStore(store => store)
  const breakpoints = useBreakpoints()
  const getClasses = ():string => {
    let classes:string = `content-layout ${props.className}`
    if (showMenuDrawer && !breakpoints.mobile) {
      classes += ` drawer-opened`
    }
    if (!showMenuDrawer && !breakpoints.mobile) {
      classes += ` drawer-closed`
    }
    if (breakpoints.mobile) {
      classes += ' is-mobile'
    }
    classes += ` ${currentTheme}`
    return classes
  }
  return (
    <div id="blank-layout" className={getClasses()} style={{ position: 'relative' }}>
      {breakpoints.mobile && <MainDrawer />}
      {!breakpoints.mobile && <MainDrawerFixed />}
      {props.children}
    </div>
  )
})

export default BlankLayout
