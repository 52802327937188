export const langs = ['pt', 'en', 'es', 'fr']

// const _joinTranslations = (languages, translations) => {
//   const t = {}

//   for (let i = 0; i < languages.length; i++) {
//     const lang = languages[i]
//     t[lang] = {}

//     for (const key in translations) {
//       t[lang] = {
//         ...t[lang],
//         ...translations[key][lang]
//       }
//     }
//   }

//   return t
// }

const translations = {
  "pt": {
    "searchFieldPlaceholder": "Pesquisar Município...",
    "ids": "IDS-COVID-19",
    "curvas": "Curvas",
    "indiceDeDesigualdadesSociais": "Índice de Desigualdade Social em Saúde",
    "momentosTitle": "Momentos IDS-COVID-19",
    "padraoEp": "Padrão Epidemiológico",
    "momentoCardTitle": "Momentos da Pandemia Covid-19",
    "momento1": "Momento 1",
    "momento2": "Momento 2",
    "momento3": "Momento 3",
    "momento4": "Momento 4",
    "momento5": "Momento 5",
    "macroRegioesPlaceholder": "Selecione uma ou várias Regiões do Brasil",
    "ufsPlaceholder": "Selecione um ou vários Estados",
    "ufsSelected": "{0} Estados Selecionados",
    "regiaoSaudePlaceholder": "Selecione uma ou várias Regiões de Saúde",
    "cidadePlaceholder": "Selecione um ou vários Municípios (máx: 10)",
    "favelaLabel": "Municípios com Favela",
    "filtroButtonLabel": "Aplicar Filtros",
    "reiniciarButtonLabel": "Reiniciar",
    "Inexistente": "Sem informação",
    "Muito Baixo": "Muito Baixo",
    "Baixo": "Baixo",
    "Médio": "Médio",
    "Desigual": "Alto",
    "Muito Desigual": "Muito Alto",
    "Padrão 1": "Ausência de informação",
    "Padrão 2": "Extremamente baixa",
    "Padrão 3": "Muito baixa",
    "Padrão 4": "Baixa",
    "Padrão 5": "Moderada",
    "Padrão 6": "Alta",
    "Padrão 7": "Muito alta",
    "Padrão 8": "Extremamente alta",
    "histograma": "Histograma",
    "momento": "Momento",
    "indice": "Índice (Q5)",
    "nivel": "Nível",
    "favelas": "Favelas",
    "sim": "Sim",
    "nao": "Não",
    "rankingUf": "Ranking UF",
    "rankingRegiao": "Ranking Região",
    "rankingBrasil": "Ranking Brasil",
    "componenteDoIds": "Domínios do IDS",
    "indiceDePrivação": "Índice brasileiro de privação",
    "sociodemografico": "Sociodemográfico",
    "acessoSaude": "Dificuldade de acesso aos serviços de saúde",
    "idsIncidenciaMortalidadeTab": "Incidência",
    "idsIncidenciaMortalidadeSragTab": "Mortalidade SRAG Covid-19",
    "idsMortalidadeSrag60Tab": "Mortalidade SRAG Covid-19 60 e mais",
    "idsIncidenciaMortalidadeTabMobile": "Incidência",
    "idsIncidenciaMortalidadeSragTabMobile": "Mortalidade SRAG Covid-19",
    "idsMortalidadeSrag60TabMobile": "Mortalidade SRAG Covid-19 60 e mais",  
    "emptyCurveCity": "Selecione uma cidade para continuar",
    "verCurvas": "Ver os gráficos das situações dos padrões epidemiológicos",
    "comparar": "Comparar",
    "comparativoDeMunicipios": "Comparativo de Municípios",
    "cityChartYAxisLabel": "Incidência por 100 mil habitantes",
    "cityChartTitle": "Incidencia nacional x Incidência municipal x Padrão epidemiológico",
    "cityChartYAxisLabelDeaths": "Mortalidade por 100 mil habitantes",
    "cityChartYAxisLabelDeaths60": "Mortalidade 60 e mais por 100 mil habitantes",
    "cityChartTitleDeaths": "Mortalidade x Padrão epidemiológico",
    "cityChartTitleDeaths60": "Mortalidade 60 e mais x Padrão epidemiológico",
    "regiaoDeSaude": "Região de Saúde",
    "uf": "Estado",
    "macroRegiao": "Macro Região",
    "ibge": "IBGE",
    "municipio": "Município",
    "municipios": "Municípios",
    "nivelIds": "Nível / IDS-COVID-19",
    "indiceDePrivacao": "Índice Brasileiro de Privação",
    "ass": "Dificuldade de Acesso aos Serviços de Saúde",
    "curvaDeIncidencia": "Curva de Incidência",
    "curvaDeMortalidade": "Curva de Mortalidade",
    "curvaDeMortalidade60": "Curva de Mortalidade 60 e mais",
    "tableTitle": "Outros",
    "tableTitleFragment1": " no mesmo Estado",
    "tableTitleFragment2": " na mesma Macro Região",
    "tableTitleFragment3": " na mesma Região de Saúde",
    "mapInfoDialogContentTabelaTab": "Tabela",
    "mapInfoDialogContentGraficosTab": "Gráficos",
    "mapInfoDialogContentChartTitle": "Comparação dos domínios do IDS-COVID-19 para os municípios selecionados",
    "rankings": "Rankings",
    "menuIdsTooltip": "Índice Desigualdades Sociais Covid-19",
    "menuCurvasTooltip": "Veja em cada município as taxas de incidência e mortalidade por Covid-19 por 100 mil habitantes em fases da pandemia",
    "menuClusterTooltip": "Veja os municípios agrupados por indicadores de incidência e mortalidade por Covid-19",
    "about": "Informações",
    "nivelIdsTitle": "Nível do Ids",
    "medidasIdsTitle": "Comparação com o Momento 1",
    "incidencia": "Incidência",
    "mortalidade": "Mortalidade SRAG Covid-19",
    "mortalidade60": "Mortalidade SRAG Covid-19 60 e mais",
    "statusMomentoReduziu": "reduziu a situação de desigualdade em saúde",
    "statusMomentoManteve": "manteve a situação de desigualdade em saúde",
    "statusMomentoNaoExiste": "Não existe comparação, pois estamos no primeiro momento",
    "clustersDialogTitle": "Curvas dos Padrões Epidemiológicos",
    "situacaoPadraoEpidemiologico": "Situação do Padrão Epidemiológico Escolhido (Incidência, Mortalidade ou Mortalidade 60 e mais)",
    "limpar": "Limpar",
    "btnTour" : "Refazer o tour guiado",
    "lastStep" : "Clique aqui para recomeçar o guia e rever as funcionalidades do painel.",
    "idsIntroTitle" : "Bem-vindo(a) ao Painel de Dados do Índice de Desigualdades Sociais para Covid-19 (IDS-COVID-19)",
    "idsIntro": "Este Índice mede a situação das desigualdades sociais em saúde nos municípios brasileiros durante quatro momentos diferentes da pandemia de Covid-19. Ele foi calculado com base em informações socioeconômicas (Índice Brasileiro de Privação), sociodemográficas e de dificuldade de acesso aos serviços de saúde. Siga este guia para conhecer melhor as funcionalidades.",
    "idsStep1": "Clique em “IDS-COVID-19” para consultar o Índice de um município. Ao clicar em “Curvas”, veja as taxas de incidência e mortalidade por Síndrome Respiratória Aguda Grave (SRAG) – Covid-19 em qualquer município que escolher. Ao clicar em “Padrão Epidemiológico”, avalie em quais locais do país a pandemia se comportou de maneiras semelhantes. Clique em “Informações” para ser direcionado ao site do projeto que construiu o IDS-COVID-19.",
    "idsStep2": "Aperte aqui para recolher o menu lateral e deixar o painel maior.",
    "idsStep3": "Digite o nome de um município para encontrá-lo no mapa.",
    "idsStep4": "Use o filtro para ampliar as escolhas no nível geográfico",
    "idsStep5": "Você pode selecionar uma ou várias regiões do Brasil, regiões de saúde, estados ou municípios. Também é possível filtrar se existem municípios com favelas nas áreas escolhidas.",
    "idsStep6": "Você pode filtrar os municípios que possuem favelas.",
    "idsStep7": "Ao passar o mouse sobre o mapa, você pode consultar o IDS-COVID-19 de um município.",
    "idsStep8": "O IDS-COVID-19 do município que você escolheu aparecerá neste quadro. Ele indicará como estava a situação de desigualdade social em saúde daquele local no momento 1 da pandemia (fevereiro de 2020).",
    "idsStep8_1": "Neste local, indentifique se o município possui favelas ou não.",
    "idsStep8_2": "Os municípios foram divididos em cinco grupos, de acordo com a situação de desigualdade social em saúde. Essas cinco partes, chamamos de 'Quintil'. Quanto maior o número do quintil, maior o IDS-COVID-19 do município. Veja a legenda ao pé da página.",
    "idsStep8_3": "O quadro também mostra a situação de cada um dos três domínios – Índice Brasileiro de Privação, Sociodemográfico e Dificuldade de Acesso a Serviços de Saúde – de acordo com a classificação da legenda ao pé da página, que vai de 'Sem Informação' a 'Muito Alto'",
    "idsStep9": "Selecione diferentes momentos da pandemia e compare como estava o IDS-COVID-19 do município que você escolheu em relação ao Momento 1 (fevereiro de 2020). As datas de comparação são: Momento 2 (julho de 2020), Momento 3 (março de 2021) e Momento 4 (janeiro de 2022)",
    "idsStep10": "Se você ativar a localização, o painel mostrará o IDS-COVID-19 do município onde você está.",
    "idsStep11": "Aperte aqui para colocar o painel em tela cheia.",
    "idsStep12": "Use esses botões para aumentar ou diminuir a escala de visualização do mapa.",
    "idsStep13": "As cores da legenda indicam a gradação da situação da desigualdade social em saúde: a classificação vai de muito baixo para muito alto. A classificação “Sem Informação” corresponde aos municípios com dados omitidos pelo IBGE no momento da realização do censo de 2010 ou, ainda, aos municípios que ainda não existiam no ano da realização do censo. Selecione uma ou mais cores para exibir apenas os municípios que estão na situação de desigualdade social em saúde que você escolher.",
    "idsTableStep1" : "Veja, nesta tabela, a comparação do IDS-COVID-19 dos municípios selecionados. Nela, é possível visualizar a situação dos domínios Socioeconômico, Sociodemográfico e de Dificuldade de Acesso a Serviços de Saúde em cada local.",
    "idsTableStep2" : "Faça o download dos dados selecionados nos formatos PDF, Planilha Excel e CSV.",
    "idsChartStep1" : "Também é possível visualizar a comparação do IDS-COVID-19 dos municípios selecionados em formato de gráfico de barras.",
    "curvesIntro" : "Nesta tela de 'Curvas', você pode avaliar as taxas de incidência e mortalidade por Covid-19 nos municípios brasileiros.",
    "curvesStep1" : "Escreva o nome do município que gostaria de consultar.",
    "curvesStep2" : "Veja no município escolhido o comportamento geral de novos casos de Covid-19 ao longo das semanas epidemiológicas de março de 2020 a fevereiro de 2022.",
    "curvesStep3" : "Veja no município escolhido a taxa de mortalidade acumulada por SRAG-Covid-19, padronizada por idade, ao longo das semanas epidemiológicas de março de 2020 a fevereiro de 2022.",
    "curvesStep4" : "Veja no município escolhido a taxa de mortalidade acumulada por SRAG-Covid-19 em idosos com 60 anos e mais, ao longo das semanas epidemiológicas de março de 2020 a fevereiro de 2022.",
    "curvesStep5" : "Selecione diferentes momentos da pandemia e compare as taxas de incidência e mortalidade por SRAG-Covid-19 do município que você escolheu em relação ao Momento 1 (fevereiro de 2020). As datas de comparação são: Momento 2 (julho de 2020), Momento 3 (março de 2021) e Momento 4 (janeiro de 2022).",
    "curvesStep6" : "Observe a taxa de incidência de SRAG-Covid-19 no município selecionado comparada com a taxa do Brasil e a situação do Padrão Epidemiológico do município. Isso pode ser visto ao longo das semanas epidemiológicas de março de 2020 a fevereiro de 2022.",
    "curvesStep6mapa" : "Passe o cursor sobre o município desejado para ver seu IDS-COVID-19.",
    "curvesStep7" : "Você pode comparar as taxas de incidência e mortalidade por SRAG-Covid-19 do município escolhido pelas taxas dos municípios da mesma região de saúde, do mesmo estado ou da mesma região do Brasil.",
    "curvesStep8" : "Veja na tabela a comparação das taxas do município escolhido com outros municípios da mesma região de saúde, do mesmo estado ou da mesma região do Brasil.",
    "curvesStep9" : "Você pode fazer download dos dados selecionados nos formatos de Planilha Excel, CSV e PDF.",
    "curvesStep9pag" : "Veja os demais municípios que não estão visíveis na tela.",
    "clusterIntroTitle" : "Bem-vindo(a) ao Painel de Dados do Índice de Desigualdades Sociais para Covid-19 (IDS-COVID-19)",
    "clusterIntro" : "Nesta tela de 'Padrão Epidemiológico', você pode avaliar os municípios brasileiros em que a pandemia teve um comportamento semelhante. Esses municípios com taxas similares de incidência e mortalidade por Covid-19 foram agrupados e formaram sete padrões epidemiológicos.",
    "clusterStep1" : "Veja todas as curvas de incidência de Covid-19 em cada um dos sete Padrões Epidemiológicos. É possível vê-las no Momento 1 (fevereiro de 2020), Momento 2 (julho de 2020), Momento 3 (março de 2021) da pandemia e Momento 4 (janeiro de 2022).",
    "clusterStep2" : "Você pode escolher ver as situações de incidência e mortalidade por Covid-19 nos quatro diferentes momentos da pandemia. Os momentos são: Momento 1 (fevereiro de 2020), Momento 2 (julho de 2020), Momento 3 (março de 2021) e Momento 4 (janeiro de 2022).",
    "clusterStep3" : "Veja os municípios com comportamento semelhante de incidência de Covid-19.",
    "clusterStep3b1" : "Veja os municípios com comportamento semelhante na taxa de mortalidade acumulada por SRAG-Covid-19, padronizada por idade.",
    "clusterStep3b2" : "Veja os municípios com comportamento semelhante na taxa de mortalidade acumulada por SRAG-Covid-19 em idosos com 60 anos e mais.",
    "clusterStep4" : "As cores da legenda indicam a gradação da situação do Padrão Epidemiológico escolhido, de extremamente baixa para extremamente alta. Compare as cores da legenda com as cores indicadas no mapa. Selecione uma ou mais cores para exibir apenas os municípios que estão na situação que você escolher",
    "clusterStep5" : "Veja nos gráficos as diferentes situações dos Padrões Epidemiológicos encontrados no Brasil segundo os momentos da pandemia. Os momentos são: Momento 1 (fevereiro de 2020), Momento 2 (julho de 2020), Momento 3 (março de 2021) e Momento 4 (janeiro de 2022).",
    "joyride": { back: 'Voltar', close: 'Fechar', last: 'Terminar', next: 'Próxima', open: 'Abrir o diálogo', skip: 'Pular' },
    "steplangchanger": "Você pode selecionar o idioma desejado",
    "stepthemeconfig": "Aqui você pode acessar algumas configurações de acessibilidade.",
    "help": "Ajuda",
    "ampliarVisualizacao": "Ampliar a visualização",
    "relatarUmProblema": "Relatar um Problema",
    "contactNome": "Nome",
    "contactEmail": "Email",
    "contactMessage": "Relato",
    "enviar": "Enviar",
  },
  "en": {
    "searchFieldPlaceholder": "Search City...",
    "ids": "IDS-COVID-19",
    "curvas": "Curves",
    "indiceDeDesigualdadesSociais": "Social Inequality Index",
    "padraoEp": "Epidemiological Pattern",
    "momentosTitle": "Momentos IDS-COVID-19",
    "momentoCardTitle": "IDS-COVID-19 Moments",
    "momento1": "First",
    "momento2": "Second",
    "momento3": "Third",
    "momento4": "Fourth",
    "momento5": "Fifth",
    "macroRegioesPlaceholder": "Select one or more macro-regions",
    "ufsPlaceholder": "Select one or more states",
    "ufsSelected": "{0} Selected states",
    "regiaoSaudePlaceholder": "Select one or more health regions",
    "cidadePlaceholder": "Select one or more municipalities (max: 10)",
    "favelaLabel": "Municipalities with slums",
    "filtroButtonLabel": "Apply filters",
    "reiniciarButtonLabel": "Restart",
    "Inexistente": "Nonexistent",
    "Muito Baixo": "Very Low",
    "Baixo": "Low",
    "Médio": "Medium",
    "Desigual": "Uneven",
    "Muito Desigual": "Very Uneven",
    "Padrão 1": "No information",
    "Padrão 2": "Extremely low",
    "Padrão 3": "Very Low",
    "Padrão 4": "Low",
    "Padrão 5": "Moderate",
    "Padrão 6": "High",
    "Padrão 7": "Very High",
    "Padrão 8": "Extremely high",
    "histograma": "Histogram",
    "momento": "Moment",
    "indice": "Index (Q5)",
    "nivel": "Level",
    "favelas": "Slums",
    "sim": "Yes",
    "nao": "No",
    "rankingUf": "UF Ranking",
    "rankingRegiao": "Region Ranking",
    "rankingBrasil": "Brazil Ranking",
    "componenteDoIds": "IDS-COVID-19 Domains",
    "indiceDePrivação": "Brazilian Deprivation Index",
    "sociodemografico": "Sociodemographic",
    "acessoSaude": "Difficulty in accessing health services",
    "idsIncidenciaMortalidadeTab": "Incidence curves",
    "idsIncidenciaMortalidadeSragTab": "COVID19 SRAG mortality curves",
    "idsMortalidadeSrag60Tab": "COVID19 SRAG mortality curves 60 and over",
    "idsIncidenciaMortalidadeTabMobile": "Incidence",
    "idsIncidenciaMortalidadeSragTabMobile": "COVID19 SRAG mortality",
    "idsMortalidadeSrag60TabMobile": "COVID19 SRAG mortality 60+",
    "emptyCurveCity": "Select a city to continue",
    "verCurvas": "Show All",
    "comparar": "Compare",
    "comparativoDeMunicipios": "Comparison of Municipalities",
    "cityChartYAxisLabel": "Incidence per 100,000 inhabitants",
    "cityChartTitle": "Incidence x Epidemiological Pattern",
    "cityChartYAxisLabelDeaths": "Mortality per 100,000 people",
    "cityChartYAxisLabelDeaths60": "Mortality 60 and over per 100,000 inhabitants",
    "cityChartTitleDeaths": "Mortality x Epidemiological Pattern",
    "cityChartTitleDeaths60": "Mortality 60 and over x Epidemiological Pattern",
    "regiaoDeSaude": "Health regions",
    "uf": "State",
    "macroRegiao": "Macro-regions",
    "ibge": "IBGE",
    "municipio": "Municipality",
    "municipios": "Municipalities",
    "nivelIds": "Level / IDS-COVID-19",
    "indiceDePrivacao": "Brazilian Deprivation Index",
    "ass": "Difficulty in accessing health services",
    "curvaDeIncidencia": "Incidence Curve",
    "curvaDeMortalidade": "Mortality Curve",
    "curvaDeMortalidade60": "Mortality Curve 60 and over",
    "tableTitle": "Others",
    "tableTitleFragment1": " in the same state",
    "tableTitleFragment2": " in the same macro-region",
    "tableTitleFragment3": " in the same health region",
    "mapInfoDialogContentTabelaTab": "Table",
    "mapInfoDialogContentGraficosTab": "Graphics",
    "mapInfoDialogContentChartTitle": "Comparison of the IDS domains for the selected municipalities",
    "rankings": "Rankings",
    "menuIdsTooltip": "Inequality Social Index Covid-19",
    "menuCurvasTooltip": "See in each municipality the incidence and mortality rates by Covid-19 per 100 thousand inhabitants in phases of the pandemic",
    "menuClusterTooltip": "See the municipalities grouped by indicators of incidence and mortality by covid-19",
    "about": "Informations",
    "nivelIdsTitle": "IDS level",
    "medidasIdsTitle": "Comparison with first moment",
    "incidencia": "Incidence",
    "mortalidade": "Mortality SRAG Covid-19",
    "mortalidade60": "Mortality SRAG Covid-19 60 and over",
    "statusMomentoReduziu": "Reduced inequalities in health",
    "statusMomentoManteve": "Maintained the situation of inequality in health",
    "statusMomentoNaoExiste": "There is no comparison, because we are in the first moment",
    "clustersDialogTitle": "Epidemiological Patterns Curves",
    "situacaoPadraoEpidemiologico": "Epidemiological Patterns  situations",
    "limpar": "Clear",
    "btnTour" : "Do the guided tour again",
    "lastStep" : "Click here to restart the guide and review the panel's features.",
    "idsIntroTitle" : "Welcome to the Inequality Social Index Covid-19 Data Panel (IDS-COVID-19)",
    "idsIntro": "This Index measures the situation of social inequalities in health in Brazilian municipalities during four different moments of the Covid-19 pandemic. It was calculated based on socioeconomic information (Brazilian Deprivation Index), sociodemographic information and the difficulty of accessing health services. Follow this guide to learn more about the features.",
    "idsStep1": " Click on “IDS-COVID-19” to see the Index of a municipality. By clicking on “Curves”, see the incidence and mortality rates for Severe Acute Respiratory Syndrome (SARS) - Covid-19 in any municipality you choose. By clicking on “Epidemiological Pattern”, assess in which parts of the country the pandemic behaved in similar ways. Click on “Information” to be directed to the website of the project that built IDS-COVID-19.",
    "idsStep2": "Click here to collapse the side menu and make the panel bigger.",
    "idsStep3": "Enter the name of a municipality to find it on the map.",
    "idsStep4": "Use the filter to expand the choices at the geographic level.",
    "idsStep5": "You can select one or several regions of Brazil, health regions, states or municipalities. It is also possible to filter if there are municipalities with slums in the chosen areas.",
    "idsStep6": " You can filter the municipalities that have slums.",
    "idsStep7": "The IDS-COVID-19 of the municipality you have chosen is reported in this table. It will indicate how the situation of social inequality in health was in that place at the First Moment of the pandemic (February 2020). The table also shows the situation of each of the three domains – Brazilian Index of Deprivation, Sociodemographic and Difficulty in Accessing Health Services – according to the classification of the legend at the bottom of the page, which goes from 'No Information' to 'Very High'.",
    "idsStep8": "The IDS-COVID-19 for the municipality you have chosen will appear in this box. It will indicate how the situation of social inequality in health was in that place at the First Moment of the pandemic (February 2020).",
    "idsStep8_1": "At this location, determine whether the municipality has slums or not.",
    "idsStep8_2": "*The municipalities have been divided into five groups based on their social inequality in health. These five segments are referred to as 'Quintiles.' The higher the quintile number, the higher the IDS-COVID-19 of the municipality. Please refer to the legend at the bottom of the page.",
    "idsStep8_3": "*The table also displays the status of each of the three domains – Brazilian Deprivation Index, Sociodemographic, and Difficulty in Accessing Health Services – according to the legend classification at the bottom of the page, which ranges from 'No Information' to 'Very High'.",
    "idsStep9": " Select different moments of the pandemic and compare how the IDS-COVID-19 of the municipality you chose was compared to the First Moment (February 2020). The comparison dates are: Second Moment (July 2020), Third Moment (March 2021) and Fourth Moment (January 2022).",
    "idsStep10": "If you enable location, the dashboard will show the IDS-COVID-19 of the municipality where you are located.",
    "idsStep11": "Press here to make the panel full screen.",
    "idsStep12": " The colors of the legend indicate the gradation of the situation of social inequality in health: the classification goes from very low to very high. The classification “No Information” corresponds to municipalities with data omitted by the IBGE at the time of the 2010 census, or even to municipalities that did not yet exist in the year the census was carried out. Select one or more colors to display only the municipalities that are in the situation of social inequality in health that you choose.",
    "idsStep13": "The colors of the legend indicate the gradation of the situation of social inequality in health: the classification goes from very low to very high. The classification “No Information” corresponds to municipalities with data omitted by the IBGE at the time of the 2010 census, or even to municipalities that did not yet exist in the year the census was carried out. Select one or more colors to display only the municipalities that are in the situation of social inequality in health that you choose.",
    "idsTableStep1" : "See the IDS-COVID-19 comparison of the selected municipalities in a table. It is possible to visualize the situation of the socioeconomic, sociodemographic and difficulty in accessing health services in each location.",
    "idsTableStep2" : "Download the selected data in PDF, Excel Spreadsheet and CSV formats.",
    "idsChartStep1" : "It is also possible to view the IDS-COVID-19 comparison of the selected municipalities in bar graph format.",
    "curvesIntro" : " In the 'Curves' screen, you can evaluate the incidence and mortality rates by Covid-19 in Brazilian municipalities.",
    "curvesStep1" : " Write the name of the municipality you would like to consult.",
    "curvesStep2" : "See the general behavior of new cases of Covid-19 in the chosen municipality over the epidemiological weeks from March 2020 to February 2022.",
    "curvesStep3" : "See in the chosen municipality the mortality rate accumulated by SARS-Covid-19, standardized by age, over the epidemiological weeks from March 2020 to February 2022.",
    "curvesStep4" : "See in the chosen municipality the mortality rate accumulated by SARS-Covid-19 in elderly people aged 60 years and over, over the epidemiological weeks from March 2020 to February 2022.",
    "curvesStep5" : "Select different moments of the pandemic and compare the SARS-Covid-19 incidence and mortality rates of the municipality you chose in relation to the First Moment (February 2020). The comparison dates are: Second Moment (July 2020), Third Moment (March 2021) and Fourth Moment (January 2022).",
    "curvesStep6" : " The graph will show the comparison between the selected rate (incidence, SARS-Covid-19 mortality or SARS-Covid-19 +60 mortality) in the chosen municipality and the average (Epidemiological Pattern) of the health region, state or macro-region.",
    "curvesStep6mapa" : "Hover over the desired municipality to view its IDS-COVID-19.",
    "curvesStep7" : "You can compare the SARS-Covid-19 incidence and mortality rates of the chosen municipality by the average (Epidemiological Pattern) of municipalities in the same health region, in the same state or in the same region of Brazil.",
    "curvesStep8" : " See in the table the comparison of the rates of the chosen municipality with other municipalities in the same health region, in the same state or in the same region of Brazil.",
    "curvesStep9" : " You can download the selected data in Excel Spreadsheet, CSV and PDF formats.",
    "curvesStep9pag" : "You can access a list of other municipalities that are currently not visible on the screen.",
    "clusterIntroTitle" : "Welcome to the Inequality Social Index Covid-19 Data Panel (IDS-COVID-19).",
    "clusterIntro" : " In this 'Epidemiological Pattern’ screen, you can evaluate the Brazilian municipalities where the pandemic had a similar behavior. These municipalities with similar rates of incidence and mortality from Covid-19 were grouped and established seven epidemiological patterns.",
    "clusterStep1" : "See the Covid-19 incidence curves in each of the seven Epidemiological Standards. You can see them in the First Moment (February 2020), Second Moment (July 2020), Third Moment (March 2021) of the pandemic and Fourth Moment (January 2022).",
    "clusterStep2" : " See the Epidemiological Patterns created at the four different moments of the pandemic. The comparison dates are: First Moment (February 2020), Second Moment (July 2020), Third Moment (March 2021) and Fourth Moment (January 2022). Match the colors with the legend at the bottom of the page.",
    "clusterStep3" : " Incidence – See the municipalities with similar behavior in the incidence of Covid-19. Mortality – See municipalities with similar behavior in the mortality rate accumulated by SARS-Covid-19, standardized by age. SRAG Mortality 60 and over – See municipalities with similar behavior in the mortality rate by SRAG-Covid-19 in elderly people aged 60 years and over.",
    "clusterStep3b1" : " Incidence – See the municipalities with similar behavior in the incidence of Covid-19. Mortality – See municipalities with similar behavior in the mortality rate accumulated by SARS-Covid-19, standardized by age. SRAG Mortality 60 and over – See municipalities with similar behavior in the mortality rate by SRAG-Covid-19 in elderly people aged 60 years and over.",
    "clusterStep3b2" : " Incidence – See the municipalities with similar behavior in the incidence of Covid-19. Mortality – See municipalities with similar behavior in the mortality rate accumulated by SARS-Covid-19, standardized by age. SRAG Mortality 60 and over – See municipalities with similar behavior in the mortality rate by SRAG-Covid-19 in elderly people aged 60 years and over.",
    "clusterStep4" : "The legend colors indicate the gradation of the situation of the chosen epidemiological pattern, from ‘extremely low’ to ‘extremely high’. Compare the legend colors with the colors indicated on the map. Select one or more colors to display only the municipalities that are in the situation you choose.",
    "clusterStep5" : "See in the charts the different situations of Epidemiological Patterns found in Brazil according to the moments of the pandemic. The moments are: Moment 1 (February 2020), Moment 2 (July 2020), Moment 3 (March 2021), and Moment 4 (January 2022).",
    "joyride": { back: 'Back', close: 'Close', last: 'Last', next: 'Next', open: 'Open the dialog', skip: 'Skip' },
    "steplangchanger": "Select the desired language.",
    "stepthemeconfig": "Here you can access some accessibility settings.",
    "help": "Help",
    "ampliarVisualizacao": "Expand the pattern visualization.",
    "clusterGrafico": "Curves of Epidemiological Patterns.",
    "relatarUmProblema": "Report a Problem.",
    "contactNome": "Name",
    "contactEmail": "Email",
    "contactMessage": "Message",
    "enviar": "Send",
  },
  "es": {
    "searchFieldPlaceholder": "Buscar Municipio...",
    "ids": "IDS-COVID-19",
    "curvas": "Curvas",
    "indiceDeDesigualdadesSociais": "Índice de Desigualdades Sociales",
    "padraoEp": "Patrón Epidemiológico",
    "momentosTitle": "Momentos IDS-COVID-19",
    "momentoCardTitle": "Períodos da pandemia COVID-19",
    "momento1": "Primer",
    "momento2": "Segundo",
    "momento3": "Tercer",
    "momento4": "Cuarto",
    "momento5": "Quinto",
    "macroRegioesPlaceholder": "Seleccione una o más macrorregiones",
    "ufsPlaceholder": "Seleccione uno o varios estados",
    "ufsSelected": "{0} Estados seleccionados",
    "regiaoSaudePlaceholder": "Seleccione una o varias Regiones de Salud",
    "cidadePlaceholder": "Seleccione uno o varios Municipios (max: 10)",
    "favelaLabel": "Municipios con Favela",
    "filtroButtonLabel": "Aplicar filtros",
    "reiniciarButtonLabel": "Reiniciar",
    "Inexistente": "Inexistente",
    "Muito Baixo": "Muy bajo",
    "Baixo": "Bajo",
    "Médio": "Medio",
    "Desigual": "Alto",
    "Muito Desigual": "Muy Alto",
    "Padrão 1": "Ausencia de mortalidad",
    "Padrão 2": "Extremamente baja",
    "Padrão 3": "Muy baja",
    "Padrão 4": "Baja",
    "Padrão 5": "Moderada",
    "Padrão 6": "Alta",
    "Padrão 7": "Muy alta",
    "Padrão 8": "Extremamente alta",
    "histograma": "Histograma",
    "momento": "Momento",
    "indice": "Índice (Q5)",
    "nivel": "Nivel",
    "favelas": "Chabolas",
    "sim": "Sí",
    "nao": "No",
    "rankingUf": "Clasificación UF",
    "rankingRegiao": "Clasificación de la región",
    "rankingBrasil": "Clasificación en el Brasil",
    "componenteDoIds": "Dominios del IDS-COVID-19",
    "indiceDePrivação": "Índice de privación brasileño",
    "sociodemografico": "Sociodemográfico",
    "acessoSaude": "Dificultad de acceso a servicios de salud",
    "idsIncidenciaMortalidadeTab": "Curvas de incidencia",
    "idsIncidenciaMortalidadeSragTab": "Curvas de Mortalidad COVID19 SARG",
    "idsMortalidadeSrag60Tab": "Curvas de Mortalidad COVID19 SARG 60 y más",
    "idsIncidenciaMortalidadeTabMobile": "Incidencia",
    "idsIncidenciaMortalidadeSragTabMobile": "Mortalidad COVID19 SARG",
    "idsMortalidadeSrag60TabMobile": "Mortalidad COVID19 SARG 60+",
    "emptyCurveCity": "Seleccione una ciudad para continuar",
    "verCurvas": "Ver Todas",
    "comparar": "Comparar",
    "comparativoDeMunicipios": "Comparativo de Municipios",
    "cityChartYAxisLabel": "Incidencia por 100 mil habitantes",
    "cityChartYAxisLabelDeaths": "Mortalidad por 100 mil habitantes",
    "cityChartYAxisLabelDeaths60": "Mortalidad 60 y más por 100 mil habitantes",
    "cityChartTitle": "Incidencia x Patrón Epidemiológico",
    "cityChartTitleDeaths": "Mortalidad x Padrón epidemiológico",
    "cityChartTitleDeaths60": "Mortalidad 60 y más x Padrón epidemiológico",
    "regiaoDeSaude": "Región de la Salud",
    "uf": "Estado",
    "macroRegiao": "Macrorregión",
    "ibge": "IBGE",
    "municipio": "Municipio",
    "municipios": "Municipios",
    "nivelIds": "Nivel / IDS-COVID-19",
    "indiceDePrivacao": "Índice de privación brasileño",
    "ass": "Dificultad de Acceso a Servicios de Salud",
    "curvaDeIncidencia": "Curva de Incidencia",
    "curvaDeMortalidade": "Curva de Mortalidad",
    "curvaDeMortalidade60": "Curva de Mortalidad 60 y máss",
    "tableTitle": "Otros",
    "tableTitleFragment1": " en el mismo estado",
    "tableTitleFragment2": " en la misma Macro Región",
    "tableTitleFragment3": " en la misma Región de salud",
    "mapInfoDialogContentTabelaTab": "Tabla",
    "mapInfoDialogContentGraficosTab": "Gráficos",
    "mapInfoDialogContentChartTitle": "Comparación de los dominios del IDS-COVID-19 para os municipios seleccionados",
    "rankings": "Clasificaciones",
    "menuIdsTooltip": "Índice de Desigualdad Social Covid-19",
    "menuCurvasTooltip": "Vea en cada municipio las tasas de incidencia y mortalidad por covid-19 por 100 mil habitantes en las fases de la pandemia",
    "menuClusterTooltip": "Vea los municipios agrupados por indicadores de incidencia y mortalidad por covid-19",
    "about": "Informaciones",
    "nivelIdsTitle": "Nivel del IDS",
    "medidasIdsTitle": "Comparación con el primer momento",
    "incidencia": "Incidencia",
    "mortalidade": "Mortalidad SRAG Covid-19",
    "mortalidade60": "Mortalidad SRAG Covid-19 60 y más",
    "statusMomentoReduziu": "reducción de las desigualdades en salud",
    "statusMomentoManteve": "mantuvo la situación de desigualdad en salud",
    "statusMomentoNaoExiste": "No hay comparación, porque estamos en el primer momento",
    "clustersDialogTitle": "Curvas de Patrones Epidemiológicos",
    "situacaoPadraoEpidemiologico": "Situaciones del Patrón Epidemiológico",
    "limpar": "Limpiar",
    "btnTour" : "Rehacer la visita guiada",
    "lastStep" : "Haga clic aquí para reiniciar la guía y revisar las características del panel.",
    "idsIntroTitle" : "Bienvenidos al Panel de Datos del Índice de Desigualdades Sociales por Covid-19 (IDS-COVID-19).",
    "idsIntro": "Este Índice mide la situación de las desigualdades sociales en salud en los municipios brasileños durante cuatro momentos diferentes de la pandemia de Covid-19. Se calculó a partir de información socioeconómica (Índice de privación brasileño), información sociodemográfica y dificultad de acceso a los servicios de salud. Siga esta guía para obtener más información sobre las características.",
    "idsStep1": " Haga clic en 'IDS-COVID-19' para ver el índice de un municipio. Al hacer clic en 'Curvas', consulte las tasas de incidencia y mortalidad del Síndrome Respiratorio Agudo Severo (SARS) - Covid-19 en cualquier municipio que elija. Al hacer clic en “Patrón epidemiológico”, evalúe en qué partes del país la pandemia se comportó de manera similar. Haga clic en 'Información' para ser dirigido al sitio web del proyecto que construyó IDS-COVID-19.",
    "idsStep2": " Haga clic aquí para colapsar el menú lateral y agrandar el panel.",
    "idsStep3": "Introduce el nombre de un municipio para encontrarlo en el mapa.",
    "idsStep4": " Utilice el filtro para ampliar las opciones a nivel geográfico.",
    "idsStep5": "Puede seleccionar una o varias regiones de Brasil, regiones de salud, estados o municipios. También es posible filtrar si hay municipios con chabolas en las zonas elegidas.",
    "idsStep6": "Puedes filtrar los municipios que tienen chabolas.",
    "idsStep7": " El IDS-COVID-19 del municipio que ha elegido se informa en esta tabla. Indicará cómo era la situación de desigualdad social en salud en ese lugar en el Primer Momento de la pandemia (febrero 2020). La tabla también muestra la situación de cada uno de los tres dominios –Índice Brasileño de Privación, Sociodemográfico y Dificultad de Acceso a los Servicios de Salud – según la clasificación de la leyenda al final de la página, que va de 'Inexistente' a 'Muy Alta'.",
    "idsStep8": "En este recuadro aparecerá el IDS-COVID-19 del municipio que hayas elegido. Indicará cómo era la situación de desigualdad social en salud en ese lugar en el Primer Momento de la pandemia (febrero 2020).",
    "idsStep8_1": "*Neste local, indentifique se o município possui favelas ou não.",
    "idsStep8_2": "*Os municípios foram divididos em cinco grupos, de acordo com a situação de desigualdade social em saúde. Essas cinco partes, chamamos de 'Quintil'. Quanto maior o número do quintil, maior o IDS-COVID-19 do município. Veja a legenda ao pé da página.",
    "idsStep8_3": "*O quadro também mostra a situação de cada um dos três domínios – Índice Brasileiro de Privação, Sociodemográfico e Dificuldade de Acesso a Serviços de Saúde – de acordo com a classificação da legenda ao pé da página, que vai de 'Sem Informação' a 'Muito Alto'",
    "idsStep9": " Selecciona diferentes momentos de la pandemia y compara cómo fue el IDS-COVID-19 del municipio que elegiste en relación al Primer Momento (febrero 2020). Las fechas de comparación son: Segundo Momento (julio de 2020), Tercer Momento (marzo de 2021) y Cuarto Momento (enero de 2022)",
    "idsStep10": "Si habilitas la ubicación, el tablero mostrará el IDS-COVID-19 del municipio donde te encuentras.",
    "idsStep11": "Presione aquí para hacer que el panel se muestre a pantalla completa.",
    "idsStep12": " Los colores de la leyenda indican la gradación de la situación de desigualdad social en salud: la clasificación va de muy bajo a muy alto. La clasificación “Inexistente” corresponde a municipios con datos omitidos por el IBGE en el momento del censo de 2010, o incluso a municipios que aún no existían en el año del censo. Selecciona uno o más colores para mostrar solo los municipios que se encuentran en situación de desigualdad social en salud que elijas.",
    "idsStep13": "Compara los colores de la leyenda con el color indicado en el IDS-COVID-19 del municipio que elegiste. Seleccione uno o más colores para mostrar solo los municipios que se encuentran en situación de desigualdad social en salud que ha elegido.",
    "idsTableStep1" : " Ver la comparativa IDS-COVID-19 de los municipios seleccionados en una tabla. Es posible visualizar la situación socioeconómica, sociodemográfica y de dificultad de acceso a los servicios de salud en cada localidad.",
    "idsTableStep2" : "Descargue los datos seleccionados en formato PDF, hoja de cálculo de Excel y CSV.",
    "idsChartStep1" : "También es posible visualizar la comparativa IDS-COVID-19 de los municipios seleccionados en formato de gráfico de barras.",
    "curvesIntro" : "En esta pantalla de 'Curvas', puede evaluar las tasas de incidencia y mortalidad por Covid-19 en los municipios brasileños.",
    "curvesStep1" : "Escriba el nombre del municipio que desea consultar.",
    "curvesStep2" : "Consulte el comportamiento general de los nuevos casos de Covid-19 en el municipio elegido durante las semanas epidemiológicas de marzo de 2020 a febrero de 2022.",
    "curvesStep3" : "Ver en el municipio elegido la tasa de mortalidad acumulada por SARS-Covid-19, estandarizada por edad, durante las semanas epidemiológicas de marzo de 2020 a febrero de 2022.",
    "curvesStep4" : "Ver en el municipio elegido la tasa de mortalidad acumulada por SARS-Covid-19 en adultos mayores de 60 años y más, durante las semanas epidemiológicas de marzo de 2020 a febrero de 2022",
    "curvesStep5" : "Selecciona diferentes momentos de la pandemia y compara las tasas de incidencia y mortalidad por SARS-Covid-19 del municipio que elegiste en relación al Primer Momento (febrero 2020). Las fechas de comparación son: Segundo Momento (julio de 2020), Tercer Momento (marzo de 2021) y Cuarto Momento (enero de 2022).",
    "curvesStep6" : "El gráfico mostrará la comparación entre la tasa seleccionada (incidencia, mortalidad por SARS-Covid-19 o mortalidad por SARS-Covid-19 +60) en el municipio elegido y la media (Patrón Epidemiológico) de la región, estado o macrorregión de salud.",
    "curvesStep6mapa" : "*Passe o cursor sobre o município desejado para ver seu IDS-COVID-19",
    "curvesStep7" : "Puede comparar las tasas de incidencia y mortalidad por SARS-Covid-19 del municipio elegido por la media (Patrón Epidemiológico) de municipios de la misma región de salud, del mismo estado o de la misma región de Brasil.",
    "curvesStep8" : "Vea en la tabla la comparación de las tasas del municipio elegido con otros municipios de la misma región de salud, del mismo estado o de la misma región de Brasil.",
    "curvesStep9" : "Puede descargar datos seleccionados en formatos de hoja de cálculo Excel, CSV y PDF.",
    "curvesStep9pag" : "*Veja os demais municípios que não estão visíveis na tela",
    "clusterIntroTitle" : "Bienvenidos al Panel de Datos del Índice de Desigualdades Sociales por Covid-19 (IDS-COVID-19)",
    "clusterIntro" : "En esta pantalla de 'Patrón Epidemiológico', puede evaluar los municipios brasileños donde la pandemia tuvo un comportamiento similar. Estos municipios con tasas similares de incidencia y mortalidad por Covid-19 se agruparon y formaron siete patrones epidemiológicos.",
    "clusterStep1" : "Ver las curvas de incidencia de Covid-19 en cada una de las siete Normas Epidemiológicas. Puedes verlos en el Primer Momento (febrero de 2020), Segundo Momento (julio de 2020), Tercer Momento (marzo de 2021) de la pandemia y Cuarto Momento (enero de 2022).",
    "clusterStep2" : "Ver los Patrones Epidemiológicos creados en los cuatro diferentes momentos de la pandemia. Las fechas de comparación son: Primer Momento (febrero de 2020), Segundo Momento (julio de 2020), Tercer Momento (marzo de 2021) y Cuarto Momento (enero de 2022). Haga coincidir los colores con la leyenda en la parte inferior de la página.",
    "clusterStep3" : "Incidencia – Ver los municipios con comportamiento similar en la incidencia de Covid-19. Mortalidad – Ver municipios con comportamiento similar en la tasa de mortalidad acumulada por SARS-Covid-19, estandarizada por edad. Mortalidad SRAG 60 y más – Ver municipios con comportamiento similar en la tasa de mortalidad por SRAG-Covid-19 en adultos mayores de 60 años y más.",
    "clusterStep3b1" : "Incidencia – Ver los municipios con comportamiento similar en la incidencia de Covid-19. Mortalidad – Ver municipios con comportamiento similar en la tasa de mortalidad acumulada por SARS-Covid-19, estandarizada por edad. Mortalidad SRAG 60 y más – Ver municipios con comportamiento similar en la tasa de mortalidad por SRAG-Covid-19 en adultos mayores de 60 años y más.",
    "clusterStep3b2" : "Incidencia – Ver los municipios con comportamiento similar en la incidencia de Covid-19. Mortalidad – Ver municipios con comportamiento similar en la tasa de mortalidad acumulada por SARS-Covid-19, estandarizada por edad. Mortalidad SRAG 60 y más – Ver municipios con comportamiento similar en la tasa de mortalidad por SRAG-Covid-19 en adultos mayores de 60 años y más.",
    "clusterStep4" : "Los colores de la leyenda indican la gradación de la situación del patrón epidemiológico elegido, desde extremadamente bajo hasta extremadamente alto. Compara los colores de la leyenda con los colores indicados en el mapa. Seleccione uno o más colores para mostrar solo los municipios que se encuentran en la situación que elija",
    "clusterStep5" : "*Veja nos gráficos as diferentes situações dos Padrões Epidemiológicos encontrados no Brasil segundo os momentos da pandemia. Os momentos são: Momento 1 (fevereiro de 2020), Momento 2 (julho de 2020), Momento 3 (março de 2021) e Momento 4 (janeiro de 2022).",
    "joyride": { back: 'Atrás', close: 'Cerca', last: 'Ultimo', next: 'Próximo', open: 'Abre el cuadro de diálogo', skip: 'Saltar' },
    "steplangchanger": "clique aqui para selecionar o idioma desejado",
    "stepthemeconfig": "Theme Config",
    "help": "Ayuda",
    "ampliarVisualizacao": "Ampliar a visualização",
    "relatarUmProblema": "Relatar um Problema",
    "contactNome": "Nome",
    "contactEmail": "Email",
    "contactMessage": "Relato",
    "enviar": "Enviar",
  },
  "fr": {
    "searchFieldPlaceholder": "Entrez un nom de ville...",
    "ids": "IDS-COVID-19",
    "curvas": "Courbes",
    "indiceDeDesigualdadesSociais": "Indice d'inégalité sociale en santé",
    "padraoEp": "Modèle épidémiologique",
    "momentosTitle": "Moments IDS-COVID-19",
    "momentoCardTitle": "Moments IDS-COVID-19",
    "momento1": "Premier",
    "momento2": "Deuxième",
    "momento3": "Troisième",
    "momento4": "Quatrième",
    "momento5": "Cinquième",
    "macroRegioesPlaceholder": "Sélectionnez une ou plusieurs macro-régions",
    "ufsPlaceholder": "Sélectionnez un ou plusieurs États",
    "ufsSelected": "{0} États sélectionnés",
    "regiaoSaudePlaceholder": "Sélectionnez une ou plusieurs régions de santé",
    "cidadePlaceholder": "Sélectionnez une ou plusieurs communes (max: 10)",
    "favelaLabel": "Communes avec bidonvilles",
    "filtroButtonLabel": "Appliquer des filtres",
    "reiniciarButtonLabel": "Redémarrer",
    "Inexistente": "Inexistant",
    "Muito Baixo": "Très bas",
    "Baixo": "Bas",
    "Médio": "Moyen",
    "Desigual": "Inégale",
    "Muito Desigual": "Très inégal",
    "Padrão 1": "Pas d'Information",
    "Padrão 2": "Extrêmement bas",
    "Padrão 3": "Très bas",
    "Padrão 4": "Bas",
    "Padrão 5": "Modéré",
    "Padrão 6": "Haut",
    "Padrão 7": "Très haut",
    "Padrão 8": "Extremement haut",
    "histograma": "Histogram",
    "momento": "Moment",
    "indice": "Indice (Q5)",
    "nivel": "Niveau",
    "favelas": "Bidonvilles",
    "sim": "Oui",
    "nao": "Non",
    "rankingUf": "Classement des États",
    "rankingRegiao": "Classement des régions",
    "rankingBrasil": "Classement Brésil",
    "componenteDoIds": "Domaines IDS-COVID-19",
    "indiceDePrivação": "Indice de privation brésilien",
    "sociodemografico": "Sociodémographique",
    "acessoSaude": "Difficulté d'accès aux services de santé",
    "idsIncidenciaMortalidadeTab": "Courbes d'incidence",
    "idsIncidenciaMortalidadeSragTab": "Courbes de mortalité SRAG COVID-19",
    "idsMortalidadeSrag60Tab": "Courbes de mortalité SRAG COVID-19 pour les plus 60 ans",
    "idsIncidenciaMortalidadeTabMobile": "Incidence",
    "idsIncidenciaMortalidadeSragTabMobile": "Incidence SRAG COVID-19 pour les plus 60 ans",
    "idsMortalidadeSrag60TabMobile": "Mortalité SRAG COVID-19 pour les plus 60 ans",
    "emptyCurveCity": "Sélectionnez une ville pour continuer",
    "verCurvas": "Tout afficher",
    "comparar": "Comparer",
    "comparativoDeMunicipios": "Comparaison des communes",
    "cityChartYAxisLabel": "Incidence pour 100 000 habitants",
    "cityChartTitle": "Incidence vs modèle épidémiologique",
    "cityChartYAxisLabelDeaths": "Mortalité pour 100 000 personnes",
    "cityChartYAxisLabelDeaths60": "Mortalité 60 ans et plus pour 100 000 habitants",
    "cityChartTitleDeaths": "Mortalité vs modèle épidémiologique",
    "cityChartTitleDeaths60": "Mortalité pour les plus de 60 ans vs modèle épidémiologique",
    "regiaoDeSaude": "Régions sanitaires",
    "uf": "État",
    "macroRegiao": "Macro-régions",
    "ibge": "IBGE",
    "municipio": "Commune",
    "municipios": "Communes",
    "nivelIds": "Niveau / IDS-COVID-19",
    "indiceDePrivacao": "Indice de défavorisation brésilien",
    "ass": "Difficulté d'accès aux services de santé",
    "curvaDeIncidencia": "Courbe d'incidence",
    "curvaDeMortalidade": "Courbe de mortalité",
    "curvaDeMortalidade60": "Courbe de mortalité pour les plus de 60 ans",
    "tableTitle": "Autres",
    "tableTitleFragment1": " dans le même état",
    "tableTitleFragment2": " dans la même macro-région",
    "tableTitleFragment3": " dans la même région de santé",
    "mapInfoDialogContentTabelaTab": "Tableau",
    "mapInfoDialogContentGraficosTab": "Graphiques",
    "mapInfoDialogContentChartTitle": "Comparaison des domaines IDS pour les communes sélectionnées",
    "rankings": "Classements",
    "menuIdsTooltip": "Indice d'inégalité sociale en santé",
    "menuCurvasTooltip": "Voir dans chaque commune les taux d'incidence et de mortalité par Covid-19 pour 100 mille habitants en phases de la pandémie",
    "menuClusterTooltip": "Voir les communes regroupées par indicateurs d'incidence et de mortalité par covid-19",
    "about": "Informations",
    "nivelIdsTitle": "Niveau IDS",
    "medidasIdsTitle": "Comparaison avec le premier moment",
    "incidencia": "Incidence",
    "mortalidade": "Mortalité SRAG Covid-19",
    "mortalidade60": "Mortalité SRAG Covid-19 pour les plus de 60 ans",
    "statusMomentoReduziu": "L'inégalité s'est réduite",
    "statusMomentoManteve": "L'inégalité s'est maintenue",
    "statusMomentoNaoExiste": "Il n'y a pas d'inégalité",
    "clustersDialogTitle": "Courbes des Modèles Épidémiologiques",
    "situacaoPadraoEpidemiologico": "Situations des Modèles Épidémiologiques",
    "limpar": "Effacer",
    "btnTour" : "Faire à nouveau la visite guidée",
    "lastStep" : "Cliquez ici pour redémarrer la visite guidé et revoir les fonctionnalités du panneau.",
    "idsIntroTitle" : "Bienvenue dans le panneau de données de l'Indice d'Inégalité Social en Santé Covid-19 (IDS-COVID-19).",
    "idsIntro": "Cet indice mesure la situation des inégalités sociales en santé dans les municipalités brésiliennes pendant quatre moments différents de la pandémie de Covid-19. Il a été calculé sur la base des informations socioéconomiques (Indice brésilien de privation), des informations sociodémographiques et de la difficulté d'accès aux services de santé. Suivez ce guide pour en savoir plus sur les fonctionnalités.",
    "idsStep1": "Cliquez sur « IDS-COVID-19 » pour voir l'indice d'une municipalité. En cliquant sur « Courbes », voyez les taux d'incidence et de mortalité du Syndrome Respiratoire Aigu Sévère (SRAS) - Covid-19 dans toute municipalité de votre choix. En cliquant sur « Modèle Épidémiologique », évaluez dans quelles parties du pays la pandémie s'est comportée de manière similaire. Cliquez sur « Informations » pour être dirigé vers le site web du projet qui a construit IDS-COVID-19.",
    "idsStep2": "Cliquez ici pour réduire le menu latéral et agrandir le panneau.",
    "idsStep3": "Entrez le nom d'une municipalité pour la trouver sur la carte.",
    "idsStep4": "Utilisez le filtre pour élargir les choix au niveau géographique.",
    "idsStep5": "Vous pouvez sélectionner une ou plusieurs régions du Brésil, des régions de santé, des États ou des municipalités. Il est également possible de filtrer s'il y a des municipalités avec des bidonvilles dans les zones choisies.",
    "idsStep6": "Vous pouvez filtrer les municipalités qui ont des bidonvilles.",
    "idsStep7": "L'IDS-COVID-19 de la municipalité que vous avez choisie est rapporté dans ce tableau. Cela indiquera comment était la situation de l'inégalité sociale en santé à cet endroit au Premier Moment de la pandémie (février 2020). Le tableau montre également la situation de chacun des trois domaines - Indice Brésilien de Privation, Sociodémographique et Difficulté d'Accès aux Services de Santé - selon la classification de la légende en bas de la page, qui va de 'Pas d'Information' à 'Très Haut'.",
    "idsStep8": "L'IDS-COVID-19 pour la municipalité que vous avez choisie apparaîtra dans cette boîte. Cela indiquera comment était la situation de l'inégalité sociale en santé à cet endroit au Premier Moment de la pandémie (février 2020).",
    "idsStep8_1": "À cet endroit, identifiez si la municipalité a des bidonvilles ou non.",
    "idsStep8_2": "Les municipalités ont été divisées en cinq groupes, selon la situation de l'inégalité sociale en santé. Ces cinq parties, nous les appelons 'Quintile'. Plus le numéro du quintile est élevé, plus l'IDS-COVID-19 de la municipalité est élevé. Voir la légende en bas de la page.",
    "idsStep8_3": "Le tableau montre également la situation de chacun des trois domaines de l'Indice Brésilien de Privation, Sociodémographique et Difficulté d'Accès aux Services de Santé - selon la classification de la légende en bas de la page, qui va de 'Pas d'Information' à 'Très Haut",
    "idsStep9": "Sélectionnez différents moments de la pandémie et comparez comment l'IDS-COVID-19 de la municipalité que vous avez choisie a été comparé au Premier Moment (février 2020). Les dates de comparaison sont : Deuxième Moment (juillet 2020), Troisième Moment (mars 2021) et Quatrième Moment (janvier 2022).",
    "idsStep10": "Si vous activez la localisation, le tableau de bord montrera l'IDS-COVID-19 de la municipalité où vous vous trouvez.",
    "idsStep11": "Appuyez ici pour mettre le panneau en plein écran.",
    "idsStep12": "Les couleurs de la légende indiquent la gradation de la situation de l'inégalité sociale en santé : la classification va de très bas à très haut. La classification « Pas d'Information » correspond aux municipalités dont les données ont été omises par l'IBGE au moment du recensement de 2010, ou même aux municipalités qui n'existaient pas encore l'année où le recensement a été réalisé. Sélectionnez une ou plusieurs couleurs pour afficher uniquement les municipalités qui sont dans la situation d'inégalité sociale en santé que vous choisissez.",
    "idsStep13": "The colors of the legend indicate the gradation of the situation of social inequality in health: the classification goes from very low to very high. The classification “No Information” corresponds to municipalities with data omitted by the IBGE at the time of the 2010 census, or even to municipalities that did not yet exist in the year the census was carried out. Select one or more colors to display only the municipalities that are in the situation of social inequality in health that you choose.",
    "idsTableStep1" : "Voir la comparaison de l'IDS-COVID-19 des municipalités sélectionnées dans un tableau. Il est possible de visualiser la situation du socioéconomique, sociodémographique et de la difficulté d'accès aux services de santé dans chaque emplacement.",
    "idsTableStep2" : "Téléchargez les données sélectionnées aux formats PDF, feuille de calcul Excel et CSV.",
    "idsChartStep1" : "Il est également possible de voir la comparaison de l'IDS-COVID-19 des municipalités sélectionnées au format de graphique à barres.",
    "curvesIntro" : "Dans l'écran 'Courbes', vous pouvez évaluer les taux d'incidence et de mortalité par Covid-19 dans les municipalités brésiliennes.",
    "curvesStep1" : "Écrivez le nom de la municipalité que vous souhaitez consulter.",
    "curvesStep2" : "Voir le comportement général des nouveaux cas de Covid-19 dans la municipalité choisie au cours des semaines épidémiologiques de mars 2020 à février 2022.",
    "curvesStep3" : "Voir dans la municipalité choisie le taux de mortalité accumulé par SRAS-Covid-19, standardisé par âge, au cours des semaines épidémiologiques de mars 2020 à février 2022.",
    "curvesStep4" : "Voir dans la municipalité choisie le taux de mortalité accumulé par SRAS-Covid-19 chez les personnes âgées de 60 ans et plus, au cours des semaines épidémiologiques de mars 2020 à février 2022.",
    "curvesStep5" : "Sélectionnez différents moments de la pandémie et comparez les taux d'incidence et de mortalité par SRAS-Covid-19 de la municipalité que vous avez choisie par rapport au Premier Moment (février 2020). Les dates de comparaison sont : Deuxième Moment (juillet 2020), Troisième Moment (mars 2021) et Quatrième Moment (janvier 2022).",
    "curvesStep6" : "Le graphique montrera la comparaison entre le taux sélectionné (incidence, mortalité par SRAS-Covid-19 ou mortalité par SRAS-Covid-19 +60) dans la municipalité choisie et la moyenne (Modèle Épidémiologique) de la région de santé, de l'État ou de la macro-région.",
    "curvesStep6mapa" : "Passez le curseur sur la municipalité souhaitée pour voir son IDS-COVID-19",
    "curvesStep7" : "Vous pouvez comparer les taux d'incidence et de mortalité par SRAS-Covid-19 de la municipalité choisie par la moyenne (Modèle Épidémiologique) des municipalités dans la même région de santé, dans le même État ou dans la même région du Brésil.",
    "curvesStep8" : "Voir dans le tableau la comparaison des taux de la municipalité choisie avec d'autres municipalités dans la même région de santé, dans le même État ou dans la même région du Brésil.",
    "curvesStep9" : "Vous pouvez télécharger les données sélectionnées aux formats feuille de calcul Excel, CSV et PDF.",
    "curvesStep9pag" : "Voir les autres municipalités qui ne sont pas visibles à l'écran.",
    "clusterIntroTitle" : "Bienvenue dans le panneau de données de l'Indice d'Inégalité Social en Santé Covid-19 (IDS-COVID-19).",
    "clusterIntro" : "Dans cet écran 'Modèle Épidémiologique', vous pouvez évaluer les municipalités brésiliennes où la pandémie a eu un comportement similaire. Ces municipalités avec des taux similaires d'incidence et de mortalité du Covid-19 ont été regroupées et établies sept modèles épidémiologiques.",
    "clusterStep1" : "Voir les courbes d'incidence du Covid-19 dans chacun des sept Standards Épidémiologiques. Vous pouvez les voir au Premier Moment (février 2020), au Deuxième Moment (juillet 2020), au Troisième Moment (mars 2021) de la pandémie et au Quatrième Moment (janvier 2022).",
    "clusterStep2" : "Voir les Modèles Épidémiologiques créés aux quatre moments différents de la pandémie. Les dates de comparaison sont : Premier Moment (février 2020), Deuxième Moment (juillet 2020), Troisième Moment (mars 2021) et Quatrième Moment (janvier 2022). Assortissez les couleurs avec la légende en bas de la page.",
    "clusterStep3" : "Incidence – Voir les municipalités avec un comportement similaire dans l'incidence du Covid-19. Mortalité – Voir les municipalités avec un comportement similaire dans le taux de mortalité accumulé par SRAS-Covid-19, standardisé par âge. Mortalité par SRAS 60 ans et plus – Voir les municipalités avec un comportement similaire dans le taux de mortalité par SRAS-Covid-19 chez les personnes âgées de 60 ans et plus.",
    "clusterStep3b1" : "Incidence – Voir les municipalités avec un comportement similaire dans l'incidence du Covid-19. Mortalité – Voir les municipalités avec un comportement similaire dans le taux de mortalité accumulé par SRAS-Covid-19, standardisé par âge. Mortalité par SRAS 60 ans et plus – Voir les municipalités avec un comportement similaire dans le taux de mortalité par SRAS-Covid-19 chez les personnes âgées de 60 ans et plus.",
    "clusterStep3b2" : "Incidence – Voir les municipalités avec un comportement similaire dans l'incidence du Covid-19. Mortalité – Voir les municipalités avec un comportement similaire dans le taux de mortalité accumulé par SRAS-Covid-19, standardisé par âge. Mortalité par SRAS 60 ans et plus – Voir les municipalités avec un comportement similaire dans le taux de mortalité par SRAS-Covid-19 chez les personnes âgées de 60 ans et plus.",
    "clusterStep4" : "Les couleurs de la légende indiquent la gradation de la situation du modèle épidémiologique choisi, allant de « extrêmement bas » à « extrêmement élevé ». Comparez les couleurs de la légende avec les couleurs indiquées sur la carte. Sélectionnez une ou plusieurs couleurs pour afficher uniquement les municipalités qui sont dans la situation que vous choisissez.",
    "clusterStep5" : "Regardez dans les graphiques les différentes situations des Modèles Épidémiologiques trouvés au Brésil selon les moments de la pandémie. Les moments sont : Moment 1 (février 2020), Moment 2 (juillet 2020), Moment 3 (mars 2021) et Moment 4 (janvier 2022).",
    "joyride": { back: 'Précédant', close: 'Fermer', last: 'Dernier', next: 'Suivant', open: 'Ouvrir le dialogue', skip: 'Passer' },
    "steplangchanger": "cliquez ici pour sélectionner la langue souhaitée",
    "stepthemeconfig": "Configuration du thème",
    "help": "Aide",
    "ampliarVisualizacao": "Élargir l'affichage des données",
    "relatarUmProblema": "Signaler un problème",
    "contactNome": "Nom",
    "contactEmail": "Email",
    "contactMessage": "Rapport",
    "enviar": "Envoyer",
  }
}

export default translations
