import { Button } from "primereact/button";
import { MenuItem, MenuItemOptions } from "primereact/menuitem";
import { classNames } from "primereact/utils";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "../TranslationProvider";
import getMenuItems from "./MainMenuItems";
import './AppIconMenu.scss'

export default function AppIconMenu() {
  const location = useLocation()
  const t = useTranslation()
  const navigate = useNavigate()

  const comparePath = (target:string) => location.pathname === target ? 'active' : ''

  const template = (item:MenuItem, options:MenuItemOptions) => {
    return (<Link to={item.url || '#'} className={options.className} target={item.target} onClick={(e) => {
        options.onClick(e)
      }}>
      <span className={classNames(options.iconClassName)}></span>
      <span className={options.labelClassName}>{item.label}</span>
    </Link>)
  }

  const menuModel = getMenuItems(comparePath, template).map(item => ({ ...item, label: t[item.tKey] }))

  const buttonClick = (item:any, e:React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (item.url) {
      navigate(item.url)
    }
    if (item.command) {
      item.command({ originalEvent: e, item })
    }
  }

  const mapTKeys:{[k:string]: string} = {
    'ids': 'menuIdsTooltip',
    'curvas': 'menuCurvasTooltip',
    'padraoEp': 'menuClusterTooltip',
    'help': 'help',
    'about': 'about',
  }

  return (<div className="app-icon-menu">
    {menuModel.map((item, index) => {
      const { tKey } = item as any
      return <div key={index}>
      <Button
        className={`p-button-lg p-button-rounded p-button-text p-button-plain`}
        icon={item.icon}
        onClick={(e) => buttonClick(item, e)}
        tooltip={t[mapTKeys[tKey]]}
        tooltipOptions={{ 'position': 'right', }}
      />
    </div>;
    })}
  </div>)
}