import { useState } from "react";
import MainAppbar from "../../components/MainAppbar"
import BlankLayout from "../../Layouts/BlankLayout"
import { Accordion, AccordionTab } from 'primereact/accordion'
import { Button } from "primereact/button";
import { useBreakpoints } from "react-match-breakpoints";
import { Dialog } from "primereact/dialog";
import { useTranslation } from "../../TranslationProvider";
import { InputText } from 'primereact/inputtext'
import { InputTextarea } from 'primereact/inputtextarea'

export default function Ajuda(props:IAjudaProps) {

  const [currentIndex, setCurrentIndex] = useState(-1)
  const [showDialog, setShowDialog] = useState(false)
  const [contactNome, setContactNome] = useState('')
  const [contactEmail, setContactEmail] = useState('')
  const [contactMessage, setContactMessage] = useState('')

  const breakpoints = useBreakpoints()
  const t = useTranslation()

  const dialogFooter = () => (<div className="flex justify-content-end">
    <Button label={t.enviar} />
  </div>)

  const onDialogClose = () => {
    setShowDialog(false)
    setContactNome('')
    setContactEmail('')
    setContactMessage('')
  }

  return (<BlankLayout className="ajuda-page">
    <MainAppbar
      showLogo={false}
      middle={
        <div className="container py-2">
          <h3 className="m-0">Ajuda</h3>
        </div>
      }
    />

    <div className="container">
      <Accordion activeIndex={currentIndex} onTabChange={(e) => setCurrentIndex(e.index)}>
        <AccordionTab header="Sessão 1">
          <p>"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."</p>
          <p>"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."</p>
        </AccordionTab>
        <AccordionTab header="Sessão 2">
          <p>"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."</p>
          <p>"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."</p>
        </AccordionTab>
        <AccordionTab header="Sessão 3">
          <p>"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."</p>
          <p>"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."</p>
        </AccordionTab>
        <AccordionTab header="Sessão 4">
          <p>"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."</p>
          <p>"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."</p>
        </AccordionTab>
        <AccordionTab header="Sessão 5">
          <p>"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."</p>
          <p>"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."</p>
        </AccordionTab>
      </Accordion>
      <div className="flex justify-content-center mt-6">
        <Button label={t.relatarUmProblema} className="p-button-text" onClick={() => setShowDialog(true)} />
      </div>
    </div>
    <Dialog
      header={t.relatarUmProblema}
      footer={dialogFooter}
      visible={showDialog}
      onHide={onDialogClose}
      maximized={breakpoints.mobile}
      closable
      style={{ width: '400px' }}
    >
      <p className="mt-5 mb-2">
        <span className="p-float-label">
          <InputText id="nome" value={contactNome} onChange={(e) => setContactNome(e.target.value)} style={{ width: '100%' }} />
          <label htmlFor="nome">{t.contactNome}</label>
        </span>
      </p>
      <p className="mt-5 mb-2">
        <span className="p-float-label">
          <InputText id="email" type={'email'} value={contactEmail} onChange={(e) => setContactEmail(e.target.value)} style={{ width: '100%' }} />
          <label htmlFor="email">{t.contactEmail}</label>
        </span>
      </p>
      <p className="mt-5 mb-2">
        <span className="p-float-label">
          <InputTextarea autoResize id="message" value={contactMessage} onChange={(e) => setContactMessage(e.target.value)} style={{ width: '100%', minHeight: '100px' }} />
          <label htmlFor="message">{t.contactMessage}</label>
        </span>
      </p>
    </Dialog>
  </BlankLayout>)
}

interface IAjudaProps {
  lang?: string
}
