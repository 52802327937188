import axios from 'axios'

const { NODE_ENV, REACT_APP_API_DEV, REACT_APP_API_PROD } = process.env

const api = axios.create({
  baseURL: NODE_ENV === 'production' ? REACT_APP_API_PROD : REACT_APP_API_DEV,
  headers: {
    // apikey: process.env.VUE_APP_LOGIN_TOKEN,
    // 'Content-Type': 'application/vnd.api+json',
    // 'Accept-Encoding': 'gzip, deflate'
  },
})

api.interceptors.request.use(config => {
  // if (checkIfSessionExpired()) {
  //   window.location.href = '/logout?expired=true'
  // }

  return config
})

export default api