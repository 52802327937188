import React from 'react';
import { ProgressSpinner } from 'primereact/progressspinner'
import './AppLoadingIndicator.scss'

export default function AppLoadingIndicator({ isLoading }: { isLoading: boolean }) {
  return (
    <div className={`app-loading-indicator ${ isLoading ? 'show-loading' : ''}`}>
      <div className="app-loading-logo"></div>
      <ProgressSpinner />
    </div>
  )
}