import create from 'zustand'

type ICurrentTheme = 'light'|'dark'

export type ColorSchemesTypes = 'Padrão'|'Deuteranopia'|'Protanopia'|'Tritanopia'|'Achomatopsia'

type LayoutState = {
  showMenuDrawer:boolean
  showFilterDrawer:boolean
  currentLang: string
  geoJsonKey: number
  currentTheme: ICurrentTheme
  colorScheme: ColorSchemesTypes

  setLanguage: (value:string) => void
  setMenuDrawerValue: (value:boolean) => void
  toggleMenuDrawer: () => void
  setFilterDrawerValue: (value:boolean, callback?: () => void) => void
  toggleFilterDrawer: () => void
  randomizeGeoJsonKey: () => void
  setCurrentTheme: (value:ICurrentTheme) => void
  updateTheme: ({ value }:{ value?:string }) => void
  setColorScheme: (value:ColorSchemesTypes) => void
}

const useLayoutStore = create<LayoutState>((set, get) => ({
    showMenuDrawer: true,
    showFilterDrawer: false,
    currentLang: 'pt',
    geoJsonKey: 0,
    currentTheme: window.localStorage.getItem('icoda_theme') as ICurrentTheme || 'light',
    colorScheme: 'Padrão',

    setLanguage: (value:string) => set(store =>({ ...store, currentLang: value })),
    setMenuDrawerValue: (value:boolean) => set(store =>({ ...store, showMenuDrawer: value })),
    toggleMenuDrawer: () => set(store => ({ ...store, showMenuDrawer: !store.showMenuDrawer })),
    setFilterDrawerValue: (value:boolean, callback) => {
      set(store =>({ ...store, showFilterDrawer: value }))
      callback && callback()
    },
    toggleFilterDrawer: () => set(store => ({ ...store, showFilterDrawer: !store.showFilterDrawer })),
    randomizeGeoJsonKey: () => set(store => ({ ...store, geoJsonKey: Math.round(Math.random() * 10000) })),
    updateTheme: ({ value }) => {
      const { currentTheme } = get()
      const theme = value || currentTheme
      const themeLink = document.getElementById('theme-link') as HTMLLinkElement | null;
      const body = document.getElementsByTagName('body');
      if (theme === 'light') {
        body[0].classList.remove('theme-dark')
        body[0].classList.add('theme-light')
      } else if (theme === 'dark') {
        body[0].classList.remove('theme-light')
        body[0].classList.add('theme-dark')
      }
      if (themeLink) {
        themeLink.href = `/theme/theme-${theme}.css`;
      }
      window.localStorage.setItem('icoda_theme', theme)
    },
    setCurrentTheme: (value) =>{
      const { updateTheme } = get()
      updateTheme({value})
      set(store => ({ ...store, currentTheme: value }))
    },
    setColorScheme: (value) => set(store => ({ ...store, colorScheme: value }))
  })
)

export default useLayoutStore
