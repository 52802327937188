import React from "react"
import MainDrawerContent from "./MainDrawerContent"
import './MainDrawer.scss'
import useLayoutStore from "../stores/LayoutStore"
import { Button } from "primereact/button"

export default function MainDrawerFixed() {
  const { showMenuDrawer, setMenuDrawerValue } = useLayoutStore()

  return (
    <div className={`main-drawer main-drawer-fixed ${showMenuDrawer ? 'drawer-fixed-active' : 'drawer-fixed-inactive'}`}>
      <Button
        icon="pi pi-times"
        className="p-button-rounded p-button-text p-button-plain drawer-close"
        aria-label="Fechar"
        onClick={() => setMenuDrawerValue(false)}
      />
      <div className="main-drawer-fixed-inner">
        <MainDrawerContent />
      </div>
    </div>
  )
}