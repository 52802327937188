import { getCityProperties, getCityCluster } from './../services/MapDataService';
import { MomentoType } from './types';
import create from 'zustand'
import { ILocality, ILocalityIndex } from "../interfaces/interfaces"

interface ICluster {
  cluster: {
    incidence?: number
    mortalitySRAG?: number
    mortalitySRAG60?: number
  }
}
interface IndexCluster {
  [k:string]: ICluster
}
type FilterByKeys = 'uf'|'regiao'|'regiaoSaudeCodigo'|'idsQ'|'cluster'|'favela'|'name'|'clusterIncidence'|'clusterMortalitySRAG'|'clusterMortalitySRAG60'
type FilterByValues = string[]|number[]|boolean|string|undefined

type Filter = { key: FilterByKeys, values: any, filterFn: (values:any, toFilter:any) => boolean }

type CitiesState = {
  cachedIndexProperties: {
    1?: ILocalityIndex,
    2?: ILocalityIndex,
    3?: ILocalityIndex,
    4?: ILocalityIndex,
    5?: ILocalityIndex,
  },
  cachedIndexCluster: {
    1?: IndexCluster,
    2?: IndexCluster,
    3?: IndexCluster,
    4?: IndexCluster,
    5?: IndexCluster,
  },
  fetchingProps: {1?: boolean, 2?: boolean, 3?: boolean, 4?: boolean, 5?: boolean},
  fetchingCluster: {1?: boolean, 2?: boolean, 3?: boolean, 4?: boolean, 5?: boolean},
  indexProperties?: ILocalityIndex,
  indexCluster?: IndexCluster
  filterBy: Filter[]
  hasActiveFilters: () => boolean
  isLoading: boolean
  error?: string
  
  clearError: () => void
  fetchCitiesProperties: (momento:MomentoType) => void
  fetchCitiesCluster: (momento:MomentoType) => void
  getCityProperties: (id:string, momento?:1|2|3|4|5) => ILocality|undefined
  getCityCluster: (id:string, momento?:1|2|3|4|5) => ICluster|undefined
  setCurrentCity: (id:string) => void
  unsetCurrentCity: () => void
  setFilter: (key:FilterByKeys|FilterByKeys[], value:FilterByValues) => void
  setMultipleFilters: (values:{ key: string, value: any }[]) => void
  resetFilters: () => void
  hasData: () => boolean
  hasClusterData: () => boolean
}

const filterDefaultValues:Filter[] = [
  { key: 'uf', values: undefined,
  filterFn:(values:string[], toFilter:string) => values.includes(toFilter)
  },
  { key: 'regiao', values: undefined,
  filterFn:(values:string[], toFilter:string) => values.includes(toFilter)
  },
  { key: 'regiaoSaudeCodigo', values: undefined,
  filterFn:(values:number[], toFilter:number) => values.includes(toFilter)
  },
  { key: 'idsQ', values: undefined,
  filterFn:(values:number[], toFilter:number) => values.includes(toFilter)
  },
  { key: 'clusterIncidence', values: undefined,
  filterFn:(values:number[], toFilter:number) => values.includes(toFilter)
  },
  { key: 'clusterMortalitySRAG', values: undefined,
  filterFn:(values:number[], toFilter:number) => values.includes(toFilter)
  },
  { key: 'clusterMortalitySRAG60', values: undefined,
  filterFn:(values:number[], toFilter:number) => values.includes(toFilter)
  },
  { key: 'name', values: undefined,
  filterFn:(values:string[], toFilter:string) => values.map(item => item.toLowerCase()).includes(toFilter.toLowerCase())
  },
  { key: 'favela', values: undefined,
  filterFn:(values:boolean, toFilter:boolean) => values === toFilter
  },
]

const useCitiesStore = create<CitiesState>((set, get) => ({
  indexProperties: undefined,
  indexCluster: undefined,
  cachedIndexProperties: {
    1: undefined,
    2: undefined,
    3: undefined,
    4: undefined,
    5: undefined,
  },
  cachedIndexCluster: {
    1: undefined,
    2: undefined,
    3: undefined,
    4: undefined,
    5: undefined,
  },
  fetchingProps: { 1: true },
  fetchingCluster: { 1: true },
  filterBy: [
    // ...resetFilters(filterDefaultValues)
  ],
  isLoading: false,

  fetchCitiesProperties: async (momento) => {
    const otherMoments = [1,2,3,4,5].filter(e => e !== momento)
    const { cachedIndexProperties, fetchingProps } = get()

    if (cachedIndexProperties[momento]) {
      set(store => ({ ...store, indexProperties: cachedIndexProperties[momento] }))
    } else {
      set(store => ({ ...store, isLoading: true }))
      try {
        if (fetchingProps[momento]) {
          fetchingProps[momento] = false
          const indexProperties = await getCityProperties(momento)
          let cached = {}
          if (indexProperties) {
            cached = {
              ...cachedIndexProperties,
              [momento]: indexProperties,
            }
            set(store => ({ ...store, indexProperties, cachedIndexProperties: cached, fetchingProps, isLoading: false }))
          }
          otherMoments.forEach((i) => {
            fetchingProps[i as 1|2|3|4|5] = true
            if (fetchingProps[i as 1|2|3|4|5] && !cachedIndexProperties[i as 1|2|3|4|5]) {
              getCityProperties(i).then(res => {
                cached = {
                  ...cached,
                  [i]: res,
                }
                fetchingProps[i as 1|2|3|4|5] = false
                set(store => ({ ...store, cachedIndexProperties: cached, fetchingProps }))
              })
            }
          })
        }
      } catch (e:any) {
        console.log(e)
        set(store => ({ ...store, error: e?.message, isLoading: false }))
      }
    }
  },
  fetchCitiesCluster: async (momento) => {
    const otherMoments = [1,2,3,4,5].filter(e => e !== momento)
    const { cachedIndexCluster, fetchingCluster } = get()

    if (cachedIndexCluster[momento]) {
      set(store => ({ ...store, indexCluster: cachedIndexCluster[momento] }))
    } else {
      set(store => ({ ...store, isLoading: true }))
      try {
        if (fetchingCluster[momento]) {
          fetchingCluster[momento] = false
          const indexCluster = await getCityCluster(momento)
          let cached = {}
          if (indexCluster) {
            cached = {
              ...cachedIndexCluster,
              [momento]: indexCluster,
            }
            set(store => ({ ...store, indexCluster, cachedIndexCluster: cached, fetchingCluster, isLoading: false }))
          }
          otherMoments.forEach((i) => {
            fetchingCluster[i as 1|2|3|4|5] = true
            if (fetchingCluster[i as 1|2|3|4|5] && !cachedIndexCluster[i as 1|2|3|4|5]) {
              getCityCluster(i).then(res => {
                cached = {
                  ...cached,
                  [i]: res,
                }
                fetchingCluster[i as 1|2|3|4|5] = false
                set(store => ({ ...store, cachedIndexCluster: cached, fetchingCluster }))
              })
            }
          })
        }
      } catch (e:any) {
        console.log(e)
        set(store => ({ ...store, error: e?.message, isLoading: false }))
      }
    }
  },
  clearError: () => set(store => ({ ...store, error: undefined })),
  getCityProperties: (id, momento) => {
    const{ indexProperties, cachedIndexProperties } = get()

    if (momento && cachedIndexProperties[momento]) return cachedIndexProperties[momento]![id]

    if (indexProperties) return indexProperties[id]

    return undefined
  },
  getCityCluster: (id, momento) => {
    const{ indexCluster, cachedIndexCluster } = get()

    if (momento && cachedIndexCluster[momento]) return cachedIndexCluster[momento]![id]

    if (indexCluster) return indexCluster[id]

    return undefined
  },
  setCurrentCity: (id) => {
    const { indexProperties } = get()
    set(store => ({ ...store, currentCity: indexProperties ? indexProperties[id] : undefined }))
  },
  unsetCurrentCity: () => set(store => ({ ...store, currentCity: undefined })),
  hasData: () => {
    const { indexProperties } = get()

    return indexProperties !== undefined && Object.keys(indexProperties).length > 0
  },
  hasClusterData: () => {
    const { indexCluster } = get()

    return indexCluster !== undefined && Object.keys(indexCluster).length > 0
  },
  setFilter: (key, value) => {
    let { filterBy } = get()

    if (value === undefined) {
      if(Array.isArray(key)) {
        key.forEach((k) => {
          const index = filterBy.findIndex(e => e.key === k)
          filterBy.splice(index, 1)
        })
      } else {
        const index = filterBy.findIndex(e => e.key === key)
        filterBy.splice(index, 1)
      }
    } else {
      if (Array.isArray(key)) {
        key.forEach(k => {
          const index = filterDefaultValues.findIndex(e => e.key === k)
          const indexFilterBy = filterBy.findIndex(e => e.key === k)
          if (indexFilterBy > -1) {
            filterBy[indexFilterBy] = { ...filterDefaultValues[index], values: value }
          } else {
            filterBy.push({ ...filterDefaultValues[index], values: value })
          }
        })
      } else {
        const index = filterDefaultValues.findIndex(e => e.key === key)
        const indexFilterBy = filterBy.findIndex(e => e.key === key)
        if (indexFilterBy > -1) {
          filterBy[indexFilterBy] = { ...filterDefaultValues[index], values: value }
        } else {
          filterBy.push({ ...filterDefaultValues[index], values: value })
        }
      }
    }
    set(store => ({ ...store, filterBy }))
  },
  resetFilters: () => {
    set(store => ({ ...store, filterBy: [] }))
  },
  setMultipleFilters: (values) => {
    const { filterBy } = get()

    values.forEach(v => {
      const index = filterBy.findIndex(e => e.key === v.key)
      filterBy[index].values = v.value
    })

    set(store => ({ ...store, filterBy }))
  },
  hasActiveFilters: () => {
    const { filterBy } = get()
    let hasFilterActive = false

    filterBy.forEach(({ values, key }) => {
      if (['uf', 'regiao', 'regiaoSaudeCodigo', 'name', 'favela'].includes(key) && values !== undefined) {
        hasFilterActive = true
      } else if(key === 'idsQ') {
        const defaultValue = filterDefaultValues.find(item => item.key === 'idsQ')?.values
        hasFilterActive = JSON.stringify(values) !== JSON.stringify(defaultValue)
      } else if(key === 'clusterIncidence') {
        const defaultValue = filterDefaultValues.find(item => item.key === 'clusterIncidence')?.values
        hasFilterActive = JSON.stringify(values) !== JSON.stringify(defaultValue)
      } else if(key === 'clusterMortalitySRAG') {
        const defaultValue = filterDefaultValues.find(item => item.key === 'clusterMortalitySRAG')?.values
        hasFilterActive = JSON.stringify(values) !== JSON.stringify(defaultValue)
      } else if(key === 'clusterMortalitySRAG60') {
        const defaultValue = filterDefaultValues.find(item => item.key === 'clusterMortalitySRAG60')?.values
        hasFilterActive = JSON.stringify(values) !== JSON.stringify(defaultValue)
      }
    })

    return hasFilterActive
  }
}))

export default useCitiesStore