import { AxiosResponse } from "axios"
import api from "../api"

export const getGeoJsonIds = async () => {
  let res:AxiosResponse
  try {
    res = await api.get<AxiosResponse>('/map/geojson/ids')
  } catch (e) {
    throw e
  }
  return res?.data
}

export const getGeoJsonIdsUris = async (type:'regioes'|'ufs'|'regioes-saude') => {
  let res:AxiosResponse
  try {
    res = await api.get<AxiosResponse>(`/map/geojson/uris/${type}`)
  } catch (e) {
    throw e
  }
  return res?.data
}

export function getYieldGeoJsonIds(uris:string[], momento: number) {
  return function* yieldGeoJsonIds() {
    for(let i = 0; i < uris.length; i++) {
      yield api.get<AxiosResponse>(`${uris[i]}/tempo/${momento}`)
    }
  }
}

interface IRegiaoSaudeParams {
  uf?: string[]
  pagination?: boolean
  page?: number
  codigo?: number[]
}

export const getRegiaoSaude = async (params:IRegiaoSaudeParams) => {
  let res:AxiosResponse
  try {
    var _params:string = ''
    params.uf && params.uf.forEach((item, index) => {
      if (index === 0) {
        _params += `?uf.sigla[]=${item}`
      } else {
        _params += `&uf.sigla[]=${item}`
      }
    })
    params.codigo && params.codigo.forEach((item, index) => {
      _params += `&codigo[]=${item}`
    })
    if (params.page) _params += `&_page=${params.page}`
    if (params.pagination) _params += `&pagination=true`
    res = await api.get<AxiosResponse>(`/api/regiao_saudes${_params}`)
  } catch (e) {
    throw e
  }

  return res?.data
}

export const getCityProperties = async (momento:number) => {
  let res:AxiosResponse
  try {
    res = await api.get<AxiosResponse>(`/map/geojson/ids/properties/tempo/${momento}`)
  } catch (e) {
    throw e
  }
  return res?.data
}
export const getCityCluster = async (momento:number) => {
  let res:AxiosResponse
  try {
    res = await api.get<AxiosResponse>(`/map/geojson/clusters/properties/tempo/${momento}`)
  } catch (e) {
    throw e
  }
  return res?.data
}
