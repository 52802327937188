export const searchName = (properties = {}, value = '') => {
  return properties.name.toLowerCase() === value.toLowerCase()
}

export const searchNameInArray = (properties = {}, value = []) => {
  if (value.length === 0) return true
  return value.includes(properties.name.toLowerCase())
}

export const filterIsFavela = (properties = {}, value) => properties.favela === value

export const filterRegion = (properties = {}, value = []) => 
  Array.isArray(value)
    ? value.includes(properties.regiao)
    : properties.regiao === value

export const filterNotRegion = (properties = {}, value = []) =>
  !Array.isArray(value)
    ? properties.regiao !== value
    : value.includes(properties.regiao)

export const filterIds = (properties = {}, value = []) => {
  return value.includes(properties.idsQ)
}

export const filterNotIds = (properties = {}, value = []) => !value.includes(properties.idsQ)

export const filterCluster = (properties = {}, value = []) => {
  return value.includes(properties.cluster)
}

export const filterNotCluster = (properties = {}, value = []) => !value.includes(properties.cluster)

export const filterUf = (properties = {}, value = '') =>
  Array.isArray(value)
    ? value.includes(properties.uf)
    : properties.uf.toLowerCase().indexOf(value) > -1

export const applyMapFilters = (data, { idsValues, clusterValues, isFavela, regions, states, city, regioesSaude }) => {
  const _data = { ...data }
  let filtered = [...(_data.features || [])]
  if (city && Array.isArray(city) && city.length > 0) {
    const cities = city.map(c => c.toLowerCase())
    filtered = filtered.filter(feature => searchNameInArray(feature.properties, cities))
  } else {
    filtered = [...(_data.features || [])]
    if (idsValues && idsValues.length > 0) {
      filtered = filtered.filter(feature => filterIds(feature.properties, idsValues))
    }
    if (clusterValues && clusterValues.length > 0) {
      filtered = filtered.filter(feature => filterCluster(feature.properties, clusterValues))
    }
    if (isFavela) {
      filtered = filtered.filter(feature => filterIsFavela(feature.properties, isFavela))
    }
    if (regions && regions.length > 0) {
      filtered = filtered.filter(feature => filterRegion(feature.properties, regions))
    }
    if (regioesSaude && regioesSaude.length > 0) {
      // filtered = filtered.filter(feature => filterRegion(feature.properties, regions))
      console.log(regioesSaude)
    }
    if (states && states.length > 0) {
      filtered = filtered.filter(feature => filterUf(feature.properties, states))
    }
  }
  _data.features = filtered
  return _data
}

export const applyMapFiltersArray = (data = [], { idsValues, isFavela, regions, states, city }) => {
  const modifiedData = data
    .map(featureCollection => {
      const collection = {...featureCollection}
      const features = [...collection.features]
      let filtered = []
      features.forEach(feature => {
        if (idsValues && idsValues.length > 0 && idsValues.includes(feature.properties.idsQ)) {
          filtered.push(feature)
        } else if (isFavela && feature.properties.favela === isFavela) {
          filtered.push(feature)
        } else if (regions && regions.length > 0 && regions.includes(feature.properties.regiao)) {
          filtered.push(feature)
        } else if (states && states.length > 0 && states.includes(feature.properties.uf)) {
          filtered.push(feature)
        } else if (city && typeof city === 'string' && feature.properties.uf.toLowerCase() === city.toLowerCase()) {
          filtered.push(feature)
        } else if (city && city.includes(feature.properties.uf.toLowerCase())) {
          filtered.push(feature)
        }
      })
      collection.features = filtered
      return collection
    });
  return modifiedData
}

export const multipleFiltersProperties = (featuresCollection, getPropertiesFn, getClustersFn, filters) => {
  if (!featuresCollection || featuresCollection.features.length === 0) return featuresCollection
  // console.log(filters)

  let filtered = [...featuresCollection.features]

  const _plainCluster = (id, props) => {
    if(!props || !getClustersFn) return
    const clusters = getClustersFn(id)
    if (!clusters) return
    const { incidence, mortalitySRAG, mortalitySRAG60 } = clusters?.clusters

    return {
      ...props,
      clusterIncidence: incidence,
      clusterMortalitySRAG: mortalitySRAG,
      clusterMortalitySRAG60: mortalitySRAG60,
    }
  }

  const _valueIsfilterable = (value) => {
    if (value === undefined || value === '') return false
    if (Array.isArray(value) && value.length === 0) return false
    return true
  }

  filters.forEach(filter => {
    const { key, values, filterFn } = filter
    if (values !== undefined) {
      const _applyFilter = item => {
        let props = getPropertiesFn(item.id)
        props = _plainCluster(item.id, props)
        if (!props || !Object.keys(props).includes(key) || !_valueIsfilterable(values)) return true
        return filterFn(values, props[key])
      }
      filtered = [
        ...filtered.filter(_applyFilter)
      ]
    }
  })
  return {
    type: 'FeatureCollection',
    features: filtered
  }
}