import React from 'react'
import { MenuItem, MenuItemOptions } from 'primereact/menuitem'
import { PrimeIcons } from 'primereact/api'

export default function getMenuItems(comparePath: (target:string) => string, template?: (item:MenuItem, options:MenuItemOptions) => React.ReactNode) {

  const items:MenuItem[] = [
    {
      label: 'IDS-Covid-19',
      tKey: 'ids',
      icon: PrimeIcons.COMPASS,
      className: `vertical ${comparePath('/')} menu-item-ids`,
      url: '/',
      template,
    },
    { label:
      'Curvas',
      tKey: 'curvas',
      icon: PrimeIcons.CHART_LINE,
      className: `vertical ${comparePath('/curvas')} menu-item-curvas`,
      url: '/curvas',
      template,
    },
    {
      label: 'Padrão Epidemiológico',
      tKey: 'padraoEp',
      icon: PrimeIcons.SITEMAP,
      className: `vertical ${comparePath('/cluster')} menu-item-cluster`,
      url: '/cluster',
      template,
    },
    // {
    //   label: 'Ajuda',
    //   tKey: 'help',
    //   icon: PrimeIcons.QUESTION_CIRCLE,
    //   className: `vertical ${comparePath('/ajuda')} menu-item-ajuda`,
    //   url: '/ajuda',
    //   template,
    // },
    {
      label: 'Informações',
      tKey: 'about',
      icon: PrimeIcons.INFO_CIRCLE,
      className: `vertical ${comparePath('/sobre')} menu-item-about`,
      command: () => {
        window.open('https://cidacs.bahia.fiocruz.br/idscovid19/', '_blank')?.focus()
      },
      template,
    },
    // { icon: PrimeIcons.ELLIPSIS_H, items: [{ label: 'Sobre', className: `${comparePath('/sobre')}` }], className: 'hide-subitem-icon' },
  ]

  return items
}