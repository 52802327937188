import React from 'react';
import { initBreakpoints } from 'react-match-breakpoints'
import { BrowserRouter } from "react-router-dom"
import ReactDOM from 'react-dom'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { TranslationProvider } from './TranslationProvider'
import translations from './translations'
import '/node_modules/primeflex/primeflex.css'
import '/node_modules/flag-icons/css/flag-icons.min.css'
import './index.scss'

const breakpointsConfig = {
  mobile: 'screen and (max-width: 962px)',
  large: 'screen and (min-width: 963px)',
}

const BreakpointsProvider = initBreakpoints(breakpointsConfig)

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <BreakpointsProvider>
        <TranslationProvider translations={translations}>
          <App />
        </TranslationProvider>
      </BreakpointsProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
