import { PrimeIcons } from 'primereact/api'
import { Button } from 'primereact/button'
import React, { useCallback } from 'react'
import useLayoutStore from '../stores/LayoutStore'
import Appbar from './Appbar/Appbar'
import LogoStatic from './LogoStatic'

const MainAppbar = React.memo(({ middle, end, showLogo = true }: { middle?:React.ReactNode, end?:React.ReactNode, showLogo?:boolean }) => {
  const { toggleMenuDrawer } = useLayoutStore(useCallback(store => store, []))
  return (
    <Appbar
      start={
        <div className='flex' style={{ height: '100%' }}>
          <div className="flex flex-column justify-content-center">
            <Button onClick={toggleMenuDrawer} icon={PrimeIcons.BARS} className="p-button-icon p-button-text p-button-lg p-button-rounded" />
          </div>
          {showLogo && <LogoStatic maxWidth='250px' />}
        </div>
      }
      middle={middle}
      end={end}
    />
  )
})

export default MainAppbar
