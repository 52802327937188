import React, { useCallback } from "react"
import AppLoadingIndicator from "./AppLoadingIndicator"
import LogoText from '../assets/images/ids_logo_text_transparent.png'
import useCitiesStore from '../stores/CitiesStore'
import './Logo.scss'

export default function Logo() {
  
  const { isLoading } = useCitiesStore(useCallback(store => store, []))
  return (
    <div className="app-logo flex">
      <div className="app-logo-sigla flex">
        <AppLoadingIndicator isLoading={isLoading} />
      </div>
      <div className="app-logo-text flex-1 align-self-end">
        <img src={LogoText} alt="logo" style={{maxWidth: '270px'}} />
      </div>
    </div>
  )
}
