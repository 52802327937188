import { IRegiaoSaude } from './../interfaces/interfaces';
import create from 'zustand'
import { GeoJsonObject } from 'geojson'
import { applyMapFilters } from '../utils/map/mapfilters'
import { getRegiaoSaude } from '../services/MapDataService';
import { getClusterCódigos } from '../services/ClusterService';

type FilterState = {
  momento: 0|1|2|3
  idsValues:number[]
  idsDefaultValues:number[]
  clusterValues:number[]
  clusterDefaultValues: number[]
  isFavela: boolean
  regions: string[]
  states: string[]
  citySearchString: string
  cityString: string[]
  cityStringfulfill: boolean
  countFeaturesFiltered: number
  regioesSaude?: IRegiaoSaude[]
  regioesSaudeSelected: IRegiaoSaude[]
  regioesSaudeError?: string
  isRegioesSaudeLoading: boolean

  addIds: (value:number) => void
  removeIds: (value:number) => void
  addCluster: (value:number) => void
  removeCluster: (value:number) => void
  toggleIsFavela: () => void
  setMultipleRegions: (value:string[]) => void
  addRegion: (value:string) => void
  removeRegion: (value:string) => void
  setMultipleStates: (value:string[]) => void
  addState: (value:string) => void
  removeState: (value:string) => void
  addCitySearchString: (value:string) => void
  addCityString: (value:string[]) => void
  setCityStringfulfill: (value:boolean) => void
  hasFilters: () => boolean
  applyFilters: (data:GeoJsonObject) => GeoJsonObject
  setMultiple: (filters:{ isFavela?:boolean, states?:string[], regions?:string[], city?: string[], momento?: 0|1|2|3, regioesSaude?: IRegiaoSaude[] }) => void
  fetchRegioesSaude: (states: string[]) => void
  fetchClusterCode: () => void
  resetFilterMaps: () => void
}

const useFiltersStore = create<FilterState>((set, get) => ({
  momento: 0,
  idsValues: [-2, 0, 1, 2, 3, 4, 5],
  idsDefaultValues: [-2, 0, 1, 2, 3, 4, 5],
  clusterValues: [-2, 1, 2, 3, 4, 5, 6, 7, 8 ],
  clusterDefaultValues: [-2, 1, 2, 3, 4, 5, 6, 7, 8 ],
  isFavela: false,
  regions: [],
  states: [],
  citySearchString: '',
  cityString: [],
  cityStringfulfill: false,
  countFeaturesFiltered: 0,
  regioesSaude: undefined,
  regioesSaudeSelected: [],
  isRegioesSaudeLoading: false,
  regioesSaudeError: undefined,

  addIds: (value:number) => set(store => {
    const { idsValues } = store
    idsValues.push(value)
    return ({ ...store, idsValues })
  }),
  removeIds: (value:number) => set(store => {
    const { idsValues } = store
    const index = idsValues.indexOf(value)
    idsValues.splice(index, 1)
    return ({ ...store, idsValues })
  }),
  addCluster: (value:number) => set(store => {
    const { clusterValues } = store
    clusterValues.push(value)
    return ({ ...store, clusterValues })
  }),
  removeCluster: (value:number) => set(store => {
    const { clusterValues } = store
    const index = clusterValues.indexOf(value)
    clusterValues.splice(index, 1)
    return ({ ...store, clusterValues })
  }),
  toggleIsFavela: () => set(store => ({ ...store, isFavela: !store.isFavela })),
  setMultipleRegions: (value:string[]) => set(store => {
    return ({ ...store, regions: value })
  }),
  addRegion: (value:string) => set(store => {
    const { regions } = store
    regions.push(value)
    return ({ ...store, regions })
  }),
  removeRegion: (value:string) => set(store => {
    const { regions } = store
    const index = regions.indexOf(value)
    regions.splice(index, 1)
    return ({ ...store, regions })
  }),
  setMultipleStates: (value:string[]) => set(store => {
    return ({ ...store, states: value })
  }),
  addState: (value:string) => set(store => {
    const { states } = store
    states.push(value)
    return ({ ...store, states })
  }),
  removeState: (value:string) => set(store => {
    const { states } = store
    const index = states.indexOf(value)
    states.splice(index, 1)
    return ({ ...store, states })
  }),
  addCitySearchString: (value:string) => set(store => ({ ...store, citySearchString: value })),
  addCityString: (value:string[]) => set(store => ({ ...store, cityString: value })),
  hasFilters: () => get().regions.length > 0 || get().states.length > 0 || get().isFavela,
  setCityStringfulfill: (value:boolean) => set(store => ({ ...store, cityStringfulfill: value })),
  applyFilters: (data:GeoJsonObject) => {
    const { idsValues, clusterValues, isFavela, regions, states, cityString, regioesSaudeSelected } = get()

    const result = applyMapFilters(data, { idsValues, clusterValues, isFavela, regions, states, city: cityString, regioesSaude: regioesSaudeSelected })

    return result
  },
  setMultiple: (filters) => {
    const { isFavela = false, regions = [], states = [], city = [], momento =  1, regioesSaude = [] } = filters
    set(store => ({
      ...store,
      isFavela,
      regions,
      states,
      cityString: city,
      momento,
      regioesSaudeSelected: regioesSaude,
      cityStringfulfill: city.length > 0
    }))
  },
  fetchRegioesSaude: async (states: string[]) => {
    set(store => ({ ...store, isRegioesSaudeLoading: true, regioesSaude: undefined }))
      try {
        let regioesSaude: IRegiaoSaude[] = []
        const data = await getRegiaoSaude({ uf: states, page: 1 })
        regioesSaude = data['hydra:member'].map(
          (item:any) => ({
            id: item['id'],
            type: item['@type'],
            codigo: item.codigo,
            nome: item.nome,
            uf: { id: item.uf['@id'], type: item.uf['@type'], sigla: item.uf.sigla, nome: item.uf.nome }
          })
        )
        set(store => ({ ...store, isRegioesSaudeLoading: false, regioesSaude }))
      } catch (e:any) {
        console.log(e)
        set(store => ({ ...store, regioesSaudeError: e?.message, isRegioesSaudeLoading: false }))
      }
  },
  fetchClusterCode: async () => {
    try {
      const res:{ codigo: number }[] = await getClusterCódigos()
      const clusterValues:number[] = [-2, ...res.map(item => item.codigo)]
      set(store => ({ ...store, isRegioesSaudeLoading: false, clusterValues, clusterDefaultValues: clusterValues }))
    } catch (e:any) {
      console.log(e)
    }
  },
  resetFilterMaps: () => {
    const { clusterDefaultValues, idsDefaultValues } = get()
    window.icodaRegionSelect = []
    // TODO: remover a linha abaixo no futuro
    window.icodaComponentRefs.refs = []
    set(store => ({
      ...store,
      isRegioesSaudeLoading: false,
      clusterValues: [...clusterDefaultValues],
      idsValues: [...idsDefaultValues],
      isFavela: false,
      states: [],
      regions: [],
      momento: 0,
      cityString: [],
      cityStringfulfill: false,
    }))
  }
}))

export default useFiltersStore
