import create from 'zustand'
import { MomentoType } from "./types"

type MomentoState = {
  momento: MomentoType

  setMomento: (momento:MomentoType) => void
}

const useMomentoStore =  create<MomentoState>((set, get) => ({
  momento: 1,

  setMomento: (momento) => set(store => ({ ...store, momento })),
}))

export default useMomentoStore
