import React from 'react'
import './Appbar.scss'

const Appbar = React.memo(({ start, middle, end }: { start?:React.ReactNode, middle?:React.ReactNode, end?:React.ReactNode }) => {

  return (
    <div className="appbar">
      <div className='flex'>
        {start && <div className='appbar-start'>{start}</div>}
        {start && end && <div className='flex flex-1'> </div>}
        {middle && <div className='appbar-middle'>{middle}</div>}
        {end && <div className='flex flex-1'> </div>}
        {end && <div className='appbar-end'>{end}</div>}
      </div>
    </div>
  )
})

export default Appbar
