import { AxiosResponse } from "axios"
import api from "../api"

export const getClusterCódigos = async () => {
  let res:AxiosResponse
  try {
    res = await api.get<AxiosResponse>(`/api/clusters.json?properties%5B%5D=codigo`)
  } catch (e) {
    throw e
  }
  return res?.data
}