import React, { MutableRefObject, useEffect, useCallback, lazy, Suspense, useState } from 'react'
import { GeoJSON as LeafletGeoJSON } from "leaflet"
import { Routes, Route } from 'react-router-dom'
import ReactGA from 'react-ga'
import L from 'leaflet'
import 'leaflet/dist/leaflet.css'
// import "primereact/resources/themes/rhea/theme.css"
import "primereact/resources/primereact.min.css"
import "primeicons/primeicons.css"
import icon from 'leaflet/dist/images/marker-icon.png'
import iconShadow from 'leaflet/dist/images/marker-shadow.png'
import Highcharts from 'highcharts'
import HighchartsAccessibility from 'highcharts/modules/accessibility'
import HighchartsExporting from 'highcharts/modules/exporting'
import HighchartsExportData from 'highcharts/modules/export-data'
import './App.css'
import useFiltersStore from './stores/FiltersStore'
import useMomentoStore from './stores/MomentoStore'
import useCitiesStore from './stores/CitiesStore'
import LogoStatic from './components/LogoStatic'
import useLayoutStore from './stores/LayoutStore'
import Ajuda from './screens/Ajuda/Ajuda'
const Home = lazy(() => import('./screens/Home/Home'))
const Curvas = lazy(() => import('./screens/Curvas/Curvas'))
const Cluster = lazy(() => import('./screens/Cluster/Cluster'))

ReactGA.initialize('UA-240695343-1')
ReactGA.pageview(window.location.pathname + window.location.search)

HighchartsAccessibility(Highcharts)
HighchartsExporting(Highcharts)
HighchartsExportData(Highcharts)

let DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow
});

L.Marker.prototype.options.icon = DefaultIcon;

declare global {
  interface Window {
    icodaInfo: any,
    icodaRegionSelect: any,
    icodaComponentRefs: {
      geoJsonRef: any | MutableRefObject<LeafletGeoJSON<any> | null>
      refs: any[]
      add: (ref:any) => void
      forceUpdate: () => void
      clear: () => void
    }
  }
}

window.icodaInfo = window.icodaInfo || undefined
window.icodaRegionSelect = window.icodaRegionSelect || {}
window.icodaComponentRefs = window.icodaComponentRefs || {
  geoJsonRef: undefined,
  refs: [],
  forceUpdate: () => {
    for(let i = 0; i < window.icodaComponentRefs.refs.length; i++) {
      window.icodaComponentRefs.refs[i]?.current?.forceUpdate()
    }
  },
  add: (ref) => {
    if(ref.current) {
      window.icodaComponentRefs.refs.push(ref)
    }
  },
  clear: () => {
    window.icodaComponentRefs.refs = []
  }
}

const App = React.memo(() => {
  const [features, setFeatures] = useState<any[]>([])
  const [doneFeatures, setDoneFeatures] = useState<boolean>(false)
  const { fetchClusterCode } = useFiltersStore(useCallback(store => store, []))
  const { momento } = useMomentoStore(useCallback(store => store, []))
  const { fetchCitiesProperties, fetchCitiesCluster } = useCitiesStore(useCallback(store => store, []))
  const { currentLang, updateTheme } = useLayoutStore(useCallback(store => store, []))


  const LoadingSuspense = () => <div className='flex flex-1 justify-content-center align-items-center' style={{ height: '100%' }}>
    <LogoStatic noLink />
  </div>

  function runYield(g:any, getTheData:(data:any) => void, isDone:() => void) {
    const it = g();
  
    (function _iterate(res) {

      !res.done && res.value.then((data:any) => {
        getTheData && getTheData(data)
        _iterate(it.next(data))
      })
      .catch((data:any) => it.throw(data))
  
      res.done && isDone && isDone()
  
    })(it.next())
  }

  function* yieldLoadCached() {
    for (let i = 0; i < 27; i++) {
      yield import(`./geojson/ESTADOS/${i}.json`)
    }
  }

  // const loadPromisesCached = useCallback(() => {
  //   const promises = []
  //   for (let i = 0; i < 27; i++) {
  //     promises.push(new Promise((res, rej) => {
  //       import(`./geojson/${i}.json`).then((data) => {
  //           res(data)
  //       });
  //     }))
  //   }
  //   return promises
  // }, [])

  useEffect(() => {
    updateTheme({})
    fetchClusterCode()
    fetchCitiesProperties(momento)
    fetchCitiesCluster(momento)

    // Promise.all(loadPromisesCached()).then((res) => {
    //   setFeatures(res)
    // })

    !doneFeatures && runYield(yieldLoadCached, (data) =>{
      setFeatures(prev => [...prev, data])
    }, () => {
      setDoneFeatures(true)
      console.log('done')
    })

  }, [momento, fetchClusterCode, fetchCitiesProperties, doneFeatures, fetchCitiesCluster, updateTheme])

  return (
    <Suspense fallback={<LoadingSuspense />}>
      <Routes>
        <Route path="/" element={<Home geojsonCities={features} lang={currentLang} />} />
        <Route path="/curvas" element={<Curvas lang={currentLang} />} />
        <Route path="/cluster" element={<Cluster geojsonCities={features} lang={currentLang} />} />
        <Route path="/ajuda" element={<Ajuda lang={currentLang} />} />
      </Routes>
    </Suspense>
  );
})

export default App;
