import React, { useEffect } from "react"
import useLayoutStore from '../stores/LayoutStore'
import { Sidebar } from 'primereact/sidebar'
import './MainDrawer.scss'
import MainDrawerContent from "./MainDrawerContent"
import { useBreakpoints } from "react-match-breakpoints"

export default function MainDrawer() {
  const { showMenuDrawer, setMenuDrawerValue } = useLayoutStore()
  const breakpoints = useBreakpoints()

  useEffect(() => {
    setMenuDrawerValue(!breakpoints.mobile)
  }, [breakpoints.mobile, setMenuDrawerValue])

  return (
    <Sidebar className="main-drawer" visible={showMenuDrawer} onHide={() => setMenuDrawerValue(false)}>
      <MainDrawerContent />
    </Sidebar>
  )
}
