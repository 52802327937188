import { Tooltip } from 'primereact/tooltip'
import { Link } from 'react-router-dom'
import Logo from './Logo'
import { useTranslation } from "../TranslationProvider"
import AppMenu from "./AppMenu"
import AppIconMenu from './AppIconMenu'

export default function MainDrawerContent() {
  const t = useTranslation()

  return (
    <div className="main-drawer-content">
      <div className="logo-container">
        <Link to="/">
          <Logo />
        </Link>
      </div>
      <Tooltip target=".menu-item-ids" className="ids-tooltip-drawer">
        {t.menuIdsTooltip}
      </Tooltip>
      <Tooltip target=".menu-item-curvas" className="ids-tooltip-drawer">
        {t.menuCurvasTooltip}
      </Tooltip>
      <Tooltip target=".menu-item-cluster" className="ids-tooltip-drawer">
        {t.menuClusterTooltip}
      </Tooltip>
      <Tooltip target=".menu-item-about" className="ids-tooltip-drawer">
        {t.about}
      </Tooltip>
      <AppMenu />
      <AppIconMenu />
    </div>
  )
}